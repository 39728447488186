import * as React from 'react';
import selectors from '../store/selectors';
import { ResponseError, ResponseErrorCode } from '../apis';
import BsFormFeedback from 'reactstrap/lib/FormFeedback';
import MESSAGE from '../message';
import { GetErrorMessageOptions } from '../store/selectors/global';

interface Props {
    error?: ResponseError | null;
    options?: GetErrorMessageOptions;
    data?: {
        identifier?: 'mobile' | 'email';
    };
}

export default function FormFeedback(props: Props) {
    const { error, options, data = {} } = props;

    if (!error) {
        return null;
    }

    let message = <React.Fragment>{selectors.global.getErrorMessage(error, options)}</React.Fragment>;

    if (selectors.global.getErrorCode(error) === ResponseErrorCode.InvalidCredentials) {
        message = (
            <React.Fragment>
                {MESSAGE.ERROR_USERNAME_NOT_FOUNT(data.identifier)} {MESSAGE.CHECK_AND_TRY_AGAIN}
            </React.Fragment>
        );
    }

    return <BsFormFeedback>{message}</BsFormFeedback>;
}
