import { ActionStatus, GlobalState } from '../types';
import { Reducer } from 'redux';
import {
    RESET_ACTION_STATUS,
    SET_ACTION_REQUEST_STATUS,
    SET_ACTION_RESPONSE_ERROR,
    SET_CONFIGURATION,
    SET_CONFIGURATION_STATUS,
    SET_ERROR_MESSAGE,
    SET_PAY_LINK,
    UPDATE_HEADER_TITLE,
} from '../constants';
import { ApplicationAction } from '../actions';
import { clone, unionBy } from 'lodash';
import { configuration } from '../../config';
import { ENVIRONMENT } from '@stronghold/pay-dropin';
import { LOCATION_CHANGE } from 'connected-react-router';
import segment from '../../segment';

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: GlobalState = {
    actions: [],
    configuration: clone(configuration),
    configurationStatus: 'unloaded',
    error: {
        errorCode: null,
        show: false,
    },
    headerTitle: {
        separator: '-',
        values: [],
    },
    merchant: {
        display_name: '',
        created_at: '',
        id: '',
        image_url: '',
        legal_name: '',
        short_name: '',
        street_location: { city: '', country: '', postcode: '', state: '', street1: '' },
        customization: {
            tip: {
                type: 'fixed',
                value1: 0,
                value2: 0,
                value3: 0,
            },
            items: [],
        },
    },
    publishableApiKey: {
        environment: ENVIRONMENT.live,
        publishable: true,
        key: '',
        created_at: '',
        last_used_at: '',
    },
};
const unloadedActionStatusState: ActionStatus = { name: 'login', isRequesting: false };

export const globalReducer: Reducer<GlobalState, ApplicationAction> = (
    state: GlobalState = unloadedState,
    action,
): GlobalState => {
    switch (action.type) {
        case SET_CONFIGURATION:
            segment.environment = action.payload.configuration.analytics.environment;
            return {
                ...state,
                configuration: action.payload.configuration,
                configurationStatus: 'loaded',
                merchant: action.payload.configuration.merchant || state.merchant,
                publishableApiKey: action.payload.configuration.publishable_api_key || state.publishableApiKey,
            };
        case SET_CONFIGURATION_STATUS:
            return {
                ...state,
                configurationStatus: action.payload.status,
            };
        case SET_ERROR_MESSAGE:
            return {
                ...state,
                error: {
                    show: true,
                    errorCode: action.payload.errorCode,
                },
            };
        case SET_ACTION_REQUEST_STATUS:
            let tmp = state.actions.find((el) => el.name === action.payload.name);
            tmp = tmp
                ? clone(tmp)
                : ({
                      ...unloadedActionStatusState,
                      name: action.payload.name,
                  } as ActionStatus);
            tmp.isRequesting = action.payload.isRequesting;
            return {
                ...state,
                actions: unionBy([tmp], state.actions, (el) => el.name),
            };
        case SET_ACTION_RESPONSE_ERROR:
            return {
                ...state,
                actions: state.actions.map((el) => {
                    if (el.name !== action.payload.name) return el;
                    return {
                        ...el,
                        responseError: action.payload.error,
                    };
                }),
            };
        case RESET_ACTION_STATUS:
            return {
                ...state,
                actions: unionBy(
                    [
                        {
                            ...unloadedActionStatusState,
                            name: action.payload.name,
                        },
                    ],
                    state.actions,
                    (el) => el.name,
                ),
            };
        case UPDATE_HEADER_TITLE:
            return {
                ...state,
                headerTitle: {
                    ...state.headerTitle,
                    ...action.payload.headerPartial,
                },
            };
        case SET_PAY_LINK:
            return {
                ...state,
                publishableApiKey: action.payload.link.api_keys.publishable,
                merchant: action.payload.link.merchant,
            };

        case LOCATION_CHANGE:
            return state;
    }

    return state;
};
