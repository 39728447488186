import * as React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { bindActionCreators, Dispatch } from 'redux';
import { isNewPaymentSourceLinkedAction } from '../store/actions';
import { connect } from 'react-redux';

type OwnProps = {
    showModal: boolean;
};

interface DispatchProps {
    isNewPaymentSourceLinkedAction: typeof isNewPaymentSourceLinkedAction;
}

type Props = OwnProps & DispatchProps;

class NewPaymentSourceLinkedModal extends React.Component<Props> {
    toggle = () => {
        this.props.isNewPaymentSourceLinkedAction(false);
    };

    render() {
        const { showModal } = this.props;
        const message = <span> You’ve successfully linked your bank account and can use this payment method.</span>;
        return (
            <Modal isOpen={showModal} toggle={this.toggle}>
                <ModalHeader className="text-capitalize" toggle={this.toggle}>
                    Congratulations!
                </ModalHeader>
                <ModalBody>
                    <div className="my-3">{message}</div>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.toggle}>
                        <span>Close</span>
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            isNewPaymentSourceLinkedAction,
        },
        dispatch,
    );

export default connect(null, mapDispatchToProps)(NewPaymentSourceLinkedModal);
