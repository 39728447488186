import * as React from 'react';
import Powered from './Powered';
import { MerchantModel } from '../apis';
import { connect, MapStateToPropsParam } from 'react-redux';
import { ApplicationState } from '../store';
import MerchantImg from '../components/MerchantImg';

interface Props {
    merchant: MerchantModel;
}

function StrongholdPay(props: Props) {
    return (
        <div data-sh="stronghold-pay">
            <MerchantImg />
            <h3 style={{ marginTop: 30 }}>
                <span>{props.merchant.display_name}</span>
            </h3>

            <Powered isBlack={false} />
        </div>
    );
}

const mapStateToProps: MapStateToPropsParam<Props, void, ApplicationState> = (state) => ({
    merchant: state.global.merchant,
});

export default connect(mapStateToProps)(StrongholdPay);
