import * as React from 'react';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { connect, MapStateToPropsParam } from 'react-redux';
import { ApplicationState } from '../store';
import { PaymentSource } from '@stronghold/pay-dropin';
import ListGroupItem from 'reactstrap/lib/ListGroupItem';
import FormGroup from 'reactstrap/lib/FormGroup';
import { Input } from 'reactstrap';
import Label from 'reactstrap/lib/Label';
import ListGroup from 'reactstrap/lib/ListGroup';
import MESSAGE from '../message';
import AddPaymentSourceButton from './dropin/AddPaymentSourceButton';
import { bindActionCreators, Dispatch } from 'redux';
import { deactivatePaymentSourceAction } from '../store/actions';
import PaymentSourceActions from './PaymentSourceActions';
import { uniqBy } from 'lodash';
import selectors from '../store/selectors';
import { CardType } from '../store/selectors/paymentSources';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCcVisa } from '@fortawesome/free-brands-svg-icons';
import { faCcMastercard } from '@fortawesome/free-brands-svg-icons';
import { faCcDinersClub } from '@fortawesome/free-brands-svg-icons';
import { faCcAmex } from '@fortawesome/free-brands-svg-icons';

interface StateProps {
    paymentSources: PaymentSource[];
    customerId: string | null;
    payLinkId?: string;
}

type OwnProps = {
    paymentSourceId?: string | null;
    onSelect?: (paymentSource: PaymentSource) => void;
    disabled?: boolean;
    canAddPaymentSource?: boolean;
    flush?: boolean;
};

interface DispatchProps {
    deactivatePaymentSourceAction: typeof deactivatePaymentSourceAction;
}

type Props = StateProps & OwnProps & DispatchProps;

class PaymentSourceList extends React.PureComponent<Props> {
    get selectable(): boolean {
        return !!this.props.onSelect;
    }

    onSelectPaymentSource = (paymentSource: PaymentSource) => {
        if (this.props.onSelect) {
            this.props.onSelect(paymentSource);
        }
    };

    renderCardTypeIcon = (provider: string) => {
        switch (selectors.paymentSources.getTypeOfCard(provider)) {
            case CardType.Visa:
                return <FontAwesomeIcon className="mr-2" size={'2x'} icon={faCcVisa} fixedWidth />;
            case CardType.MasterCard:
                return <FontAwesomeIcon className="mr-2" size={'2x'} icon={faCcMastercard} fixedWidth />;
            case CardType.AmericanExpress:
                return <FontAwesomeIcon className="mr-2" size={'2x'} icon={faCcAmex} fixedWidth />;
            case CardType.DinersClub:
                return <FontAwesomeIcon className="mr-2" size={'2x'} icon={faCcDinersClub} fixedWidth />;
            default:
                return null;
        }
    };

    render() {
        const {
            customerId,
            disabled,
            paymentSourceId,
            paymentSources,
            canAddPaymentSource = true,
            flush = false,
            payLinkId,
        } = this.props;

        let list = <div>{MESSAGE.PAYMENT_METHOD_NONE}</div>;
        let buttonColor = 'primary';
        let buttonText;
        let buttonIconColor;

        if (this.props.paymentSources.length > 0) {
            buttonColor = 'light';
            buttonIconColor = 'dark';
            buttonText = MESSAGE.PAYMENT_METHOD_CHANGE;
            list = (
                <ListGroup data-sh="payment-sources" flush={flush}>
                    {customerId &&
                        paymentSources &&
                        uniqBy(paymentSources, 'unique_hash').map((el, key) => {
                            let content = (
                                <Label className="mb-0" data-sh="name">
                                    <div className="d-flex align-items-center">
                                        {this.renderCardTypeIcon(el.provider_name)}
                                        <span>{el.label}</span>
                                    </div>
                                </Label>
                            );

                            if (this.selectable) {
                                content = (
                                    <FormGroup check className="d-flex align-items-center">
                                        <Input
                                            type="radio"
                                            name={`payment_source_${key}`}
                                            checked={paymentSourceId === el.id}
                                            readOnly={true}
                                            className="mt-0"
                                        />
                                        {content}
                                        <div className="ml-auto" onClick={(e) => e.stopPropagation()}>
                                            <PaymentSourceActions paymentSource={el} customerId={customerId} />
                                        </div>
                                    </FormGroup>
                                );
                            }

                            return (
                                <ListGroupItem
                                    key={key}
                                    onClick={() => this.onSelectPaymentSource(el)}
                                    active={el.id === paymentSourceId}
                                    action={this.selectable}
                                >
                                    {content}
                                </ListGroupItem>
                            );
                        })}
                </ListGroup>
            );
        }

        let button = <></>;

        if (canAddPaymentSource) {
            button = (
                <div className="text-center mt-2">
                    <AddPaymentSourceButton
                        color={buttonColor}
                        size="md"
                        iconColor={buttonIconColor}
                        disabled={disabled}
                        onSuccess={this.onSelectPaymentSource}
                        payLinkId={payLinkId}
                    >
                        {buttonText}
                    </AddPaymentSourceButton>
                </div>
            );
        }

        return (
            <React.Fragment>
                <Row className="my-3 d-flex">
                    {this.props.paymentSources.length > 0 ? (
                        <Col className="text-center">{list}</Col>
                    ) : (
                        <Col>{button}</Col>
                    )}
                </Row>
                <Row className="my-3 d-flex">
                    {this.props.paymentSources.length > 0 ? (
                        <Col>{button}</Col>
                    ) : (
                        <Col className="text-center mt-4">{list}</Col>
                    )}
                </Row>
            </React.Fragment>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, OwnProps, ApplicationState> = (state) => ({
    paymentSources: state.paymentSources.arr,
    customerId: state.authentication.customer.id,
    payLinkId: state.payLink.data?.id,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            deactivatePaymentSourceAction,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(PaymentSourceList);
