import './styles/styles.scss';
import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import PortalRoute from './components/PortalRoute';
import { ROUTE } from './config';
import LoginPage from './pages/LoginPage';
import HomePage from './pages/HomePage';
import LinkAccountPage from './pages/LinkAccountPage';
import LogoutPage from './pages/LogoutPage';
import PayLinkPage from './pages/PayLink/PayLinkPage';
import { connect, MapStateToPropsParam } from 'react-redux';
import { ApplicationState } from './store';
import { bindActionCreators, Dispatch } from 'redux';
import { fetchConfiguration } from './store/actions';
import { Helmet } from 'react-helmet';
import selectors from './store/selectors';
import ErrorHandler from './pages/ErrorHandler';
import { ConfigurationStatus } from './store/types';
import SignUpPage from './pages/SignUpPage';

interface StateProps {
    configurationStatus: ConfigurationStatus;
    title: string;
    analyticsWriteKey?: string;
}

interface DispatchProps {
    fetchConfiguration: typeof fetchConfiguration;
}

type Props = StateProps & DispatchProps;

class App extends React.PureComponent<Props> {
    async componentDidMount() {
        await this.props.fetchConfiguration();
        this.setState({ ready: true });
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        if (prevProps.analyticsWriteKey === this.props.analyticsWriteKey) return;
        if (!this.props.analyticsWriteKey) return;

        window.analytics.load(this.props.analyticsWriteKey);
    }

    public render() {
        const { configurationStatus, title } = this.props;
        if (configurationStatus === 'unloaded') {
            return null;
        }

        return (
            <React.Fragment>
                <Helmet>
                    <title>{title}</title>
                </Helmet>
                <ErrorHandler>
                    <Switch>
                        {/* Route processing pay links: l/{code} */}
                        <Route path={ROUTE.LINK} component={PayLinkPage} />

                        {/* Routes merchant specific, ex: merchant.onstrongholdpay.com */}
                        <PortalRoute path={ROUTE.LOGIN} component={LoginPage} authenticate={false} isMerchantRoute />

                        {/* Restrict to login only for now */}
                        <Route path={ROUTE.SIGN_UP} component={SignUpPage} />
                        <Route path={ROUTE.LOGOUT} component={LogoutPage} />
                        <PortalRoute
                            path={ROUTE.LINK_ACCOUNT}
                            component={LinkAccountPage}
                            authenticate
                            isMerchantRoute
                        />
                        <PortalRoute
                            path={ROUTE.HOME}
                            component={HomePage}
                            authenticate
                            allowUnauthenticated
                            paymentSourceRequired
                            isMerchantRoute
                        />
                        <Redirect to={ROUTE.HOME} />
                    </Switch>
                </ErrorHandler>
            </React.Fragment>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, void, ApplicationState> = (state) => ({
    configurationStatus: state.global.configurationStatus,
    title: selectors.global.getTitle(state.global),
    analyticsWriteKey: state.global.configuration.analytics.write_key,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            fetchConfiguration,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(App);
