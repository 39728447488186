import * as React from 'react';
import { MerchantModel } from '../apis';
import MerchantImg from './MerchantImg';
import { connect, MapStateToPropsParam } from 'react-redux';
import { ApplicationState } from '../store';

interface StateProps {
    merchant: MerchantModel;
}

type Props = StateProps;

function Merchant(props: Props) {
    const { merchant } = props;

    return (
        <div data-sh="merchant">
            <MerchantImg />
            <div className={'lead'}>{merchant.display_name}</div>
            <small>{merchant.street_location.street1}, </small>
            <small>{merchant.street_location.street2} </small>
            <small>{merchant.street_location.street3} </small>
            <small>
                {merchant.street_location.city}, {merchant.street_location.state} {merchant.street_location.postcode}
            </small>
        </div>
    );
}

const mapStateToProps: MapStateToPropsParam<StateProps, void, ApplicationState> = (state) => ({
    merchant: state.global.merchant,
});

export default connect(mapStateToProps)(Merchant);
