import * as React from 'react';
import { connect, MapStateToPropsParam } from 'react-redux';
import { ApplicationState } from '../../store';
import { bindActionCreators, Dispatch } from 'redux';
import { Card, CardBody, Button, ListGroupItem, ListGroup } from 'reactstrap';
import {
    selectPaymentSourceAction,
    setErrorMessageAction,
    updateHeaderTitleAction,
    updatePayLinkAction,
} from '../../store/actions';
import { MerchantModel, PayLinkModel, PayLinkOrderModel, PayLinkTipModel } from '../../apis';
import { ChargeOnSuccess, PaymentSource } from '@stronghold/pay-dropin';
import CardHeader from 'reactstrap/lib/CardHeader';
import AddChargeDropinButton from '../../components/dropin/AddChargeDropinButton';
import CheckoutTotal from '../../components/checkout/CheckoutTotal';
import selectors from '../../store/selectors';
import MESSAGE from '../../message';
import TipSelection from '../../components/TipSelection';
import TipMessage from '../../components/TipMessage';
import PaymentAuthorizationAggreement from '../../components/dropin/PaymentAuthorizationAggreement';
import PaymentSourceList from '../../components/PaymentSourceList';
import { CustomizationType, SPLIT_FLAG, TipCustomizationModel } from '../../store/types';
import PayLinkSuccessCard from '../../components/PayLinkSuccessCard';
import PaymentSourceRequiredWarning from '../../components/PaymentSourceRequiredWarning';
import UploadedDocuments from '../../components/UploadedDocuments';
import StrongholdPay from '../../components/StrongholdPay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import OrderItem from '../../components/checkout/OrderItem';
import ToggleSplit from '../../components/split/ToggleSplit';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';

interface StateProps {
    paymentSources: PaymentSource[];
    paymentSourceSelected: PaymentSource | null;
    isAuthorizingPayment: boolean;
    success_msg: string | null;
    order: PayLinkOrderModel;
    tipModel: TipCustomizationModel;
    tipData: PayLinkTipModel;
    merchant: MerchantModel;
    creditValue: number;
}

interface OwnProps {
    link: PayLinkModel;
}

interface DispatchProps {
    setErrorMessageAction: typeof setErrorMessageAction;
    updateHeaderTitleAction: typeof updateHeaderTitleAction;
    updatePayLinkAction: typeof updatePayLinkAction;
    selectPaymentSourceAction: typeof selectPaymentSourceAction;
}

type Props = StateProps & OwnProps & DispatchProps;

interface State {
    showSticky: boolean;
    tipValue: number;
    hasTipError: boolean;
    fileList: PayLinkOrderModel | null;
}

class CheckoutPage extends React.Component<Props, State> {
    private orderSummary: HTMLDivElement | null = null;
    private continueButton: HTMLDivElement | null = null;

    constructor(props: Props) {
        super(props);
        this.state = {
            showSticky: true,
            tipValue: 0,
            hasTipError: false,
            fileList: null,
        };
    }

    async componentDidMount() {
        await this.props.updateHeaderTitleAction({
            values: ['Checkout'],
        });

        window.addEventListener('scroll', this.onScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScroll);
    }

    onScroll = () => {
        if (this.isFirstButtonInViewport() || !this.isEndButtonInViewport()) {
            this.setState({ showSticky: true });
        } else {
            this.setState({ showSticky: false });
        }
    };

    isFirstButtonInViewport = () => {
        if (!this.continueButton) return false;
        const top = this.continueButton.getBoundingClientRect().top;
        return top >= 0 && top <= window.innerHeight;
    };

    isEndButtonInViewport = (offset = 0) => {
        if (!this.orderSummary) return false;
        const top = this.orderSummary.getBoundingClientRect().top;
        return top + offset >= 0 && top - offset <= window.innerHeight;
    };

    scrollToPayment = () => {
        if (!this.orderSummary) return false;
        window.scrollTo(0, this.orderSummary.getBoundingClientRect().top);
    };

    onSuccess: ChargeOnSuccess = (charge) => {
        this.props.updatePayLinkAction({ status: 'used', charge });
    };

    getAmountDetails = () => {
        return selectors.number.getAmountDetails({
            tipAmount: this.state.tipValue,
            tipType: this.props.tipModel.type,
            convenienceFee: this.props.order.convenience_fee,
            processingFee: this.props.order.payment_processing_fee,
            taxAmount: this.props.order.tax_amount,
            totalAmount: this.props.order.total_amount,
            subAmount: this.props.order.sub_amount,
            creditValue: this.props.creditValue,
        });
    };

    renderQuickPay = () => {
        const amountDetails = this.getAmountDetails();
        const totalAmountWithTip = selectors.number.amountToString(amountDetails.fullAmount);
        return (
            <>
                <Card
                    className={`sticky slider ${this.state.showSticky ? 'opened' : 'closed'}`}
                    data-sh="sticky-slider"
                >
                    <CardBody className="px-md-5 py-md-4 d-flex justify-content-between">
                        <div>
                            <p className="m-0 text-primary font-weight-bold">Order Total</p>
                            <p className="m-0 font-weight-bold" data-sh="amount">
                                {totalAmountWithTip}
                            </p>
                        </div>
                        <Button
                            className="text-uppercase"
                            disabled={this.props.isAuthorizingPayment}
                            color={'primary'}
                            onClick={this.scrollToPayment}
                        >
                            Pay Now
                            <FontAwesomeIcon icon={faLongArrowAltRight} fixedWidth />
                        </Button>
                    </CardBody>
                </Card>
                <div ref={(el) => (this.continueButton = el)} className="mb-3" />
                <Card></Card>
            </>
        );
    };

    renderItems = () => {
        if (this.props.order.items.length === 0) {
            return null;
        }

        return (
            <React.Fragment>
                <CardHeader>Items</CardHeader>
                <CardBody className="p-md-5">
                    <ListGroup flush>
                        {this.props.order.items.map((el, key) => (
                            <ListGroupItem key={key} className="px-0 py-md-4" id={`order-item-${key + 1}`}>
                                <OrderItem item={el} />
                            </ListGroupItem>
                        ))}
                    </ListGroup>
                </CardBody>
            </React.Fragment>
        );
    };

    renderPaymentMethod = () => {
        const header = MESSAGE.PAYMENT_METHOD_SELECT_TITLE;

        return (
            <>
                <CardHeader>
                    <strong>{header}</strong>
                </CardHeader>
                <CardBody className="p-md-5">
                    <div className="pl-md-5 pr-md-5">
                        <PaymentSourceList
                            paymentSourceId={this.props.paymentSourceSelected?.id}
                            onSelect={(paymentSource) => this.props.selectPaymentSourceAction(paymentSource.id)}
                            disabled={this.props.isAuthorizingPayment}
                        />
                    </div>
                </CardBody>
            </>
        );
    };

    renderTip = () => {
        const { tipModel, tipData, merchant } = this.props;

        const amountDetails = this.getAmountDetails();
        if (!tipData) return null;

        return (
            <React.Fragment>
                <CardHeader>
                    <strong>Add a tip</strong>
                </CardHeader>
                <CardBody className="p-md-5">
                    <div className="pl-md-5 pr-md-5">
                        <Row className="my-3 d-flex">
                            <Col>
                                <div className="text-center mb-2">{MESSAGE.SELECT_OR_ADD_TIP}</div>
                                <TipSelection
                                    originalAmount={amountDetails.totalAmountWithFees + amountDetails.processingFee}
                                    tipModel={tipModel}
                                    amount={this.state.tipValue}
                                    onSetAmount={(tipValue, hasTipError) => this.setState({ tipValue, hasTipError })}
                                />
                            </Col>
                        </Row>
                        <Row className="my-3 d-flex">
                            <Col>
                                <TipMessage tipData={tipData} merchant={merchant} />
                            </Col>
                        </Row>
                    </div>
                </CardBody>
            </React.Fragment>
        );
    };

    renderPdf = () => {
        const { order, link } = this.props;

        if (this.props.order.documents && this.props.order.documents.length === 0) {
            return null;
        }
        return (
            <React.Fragment>
                <CardHeader>Order Documents</CardHeader>
                <CardBody className="p-md-5">
                    <div className="text-center mb-3">
                        <UploadedDocuments order={order} link={link} />
                    </div>
                </CardBody>
            </React.Fragment>
        );
    };

    renderTotal = () => {
        const { isAuthorizingPayment, order, link, tipModel, paymentSourceSelected } = this.props;
        const { id, merchant } = link;
        const { tipValue, hasTipError } = this.state;

        const amountDetails = this.getAmountDetails();

        const payment = (
            <React.Fragment>
                <AddChargeDropinButton
                    payLinkCode={id}
                    paymentSourceId={paymentSourceSelected?.id || ''}
                    amount={amountDetails.totalAmountWithFees}
                    tipAmount={amountDetails.tipAmount}
                    creditAmount={amountDetails.creditValue}
                    processingFee={amountDetails.processingFee}
                    tipBeneficiaryName={link.tip ? link.tip.beneficiary_name : undefined}
                    disabled={!paymentSourceSelected?.id || isAuthorizingPayment || hasTipError}
                    onSuccess={this.onSuccess}
                    size="lg"
                    className="mt-3 mb-1"
                    block
                />
                {paymentSourceSelected ? (
                    <PaymentAuthorizationAggreement
                        merchantDisplayName={merchant.display_name}
                        paymentSourceLabel={paymentSourceSelected.label}
                        amount={amountDetails.fullAmount}
                    />
                ) : (
                    <PaymentSourceRequiredWarning />
                )}
            </React.Fragment>
        );

        return (
            <CardBody className="p-md-5 border-top">
                <div className="pl-md-5 pr-md-5">
                    <CheckoutTotal
                        totalAmount={order.total_amount}
                        convenienceFee={order.convenience_fee}
                        processingFee={order.payment_processing_fee}
                        subAmount={order.sub_amount}
                        taxAmount={order.tax_amount}
                        tipValue={tipValue || undefined}
                        tipType={tipModel.type}
                        creditValue={this.props.creditValue}
                    />
                    {payment}
                    <div ref={(el) => (this.orderSummary = el)} />
                </div>
            </CardBody>
        );
    };

    renderSuccessMsg = () => {
        const { display_name } = this.props.link.merchant;
        const { success_msg } = this.props;

        return (
            <div data-sh="description">
                <p>{`Your payment to ${display_name} was received.`}</p>
                <div>{success_msg}</div>
            </div>
        );
    };

    render() {
        const { status } = this.props.link;

        if (status === 'used') {
            return <PayLinkSuccessCard title="Success">{this.renderSuccessMsg()}</PayLinkSuccessCard>;
        }

        return (
            <div onScroll={this.onScroll} className="mt-3">
                <Card>
                    <CardBody className="p-md-5 text-center">
                        <StrongholdPay />
                    </CardBody>
                    <ToggleSplit flag={SPLIT_FLAG.SIMPLE_CHECKOUT} unactiveComponent={this.renderItems()}>
                        {null}
                    </ToggleSplit>
                    {this.renderPdf()}
                    {this.renderPaymentMethod()}
                    {this.renderTip()}
                    {this.renderTotal()}
                </Card>
            </div>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, OwnProps, ApplicationState> = (state, ownProps) => {
    return {
        paymentSources: state.paymentSources.arr,
        paymentSourceSelected: selectors.paymentSources.getPaymentSourceSelected(state),
        tipModel: state.global.merchant.customization.tip,
        success_msg: selectors.customizations.getCustomization(
            state.global.merchant.customization.items,
            CustomizationType.SuccessScreen,
        ),
        isAuthorizingPayment: selectors.global.isActionRequesting(state.global.actions, 'authorize_payment'),
        order: ownProps.link.order as PayLinkOrderModel, // Link must have an order here
        tipData: ownProps.link.tip as PayLinkTipModel,
        merchant: state.global.merchant,
        creditValue: state.credits.creditValue,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            setErrorMessageAction,
            updateHeaderTitleAction,
            updatePayLinkAction,
            selectPaymentSourceAction,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutPage);
