import { SET_CREDIT_VALUE } from '../constants';

export interface SetCreditValueAction {
    type: SET_CREDIT_VALUE;
    payload: {
        creditValue: number;
    };
}

export const setCreditValueAction = (creditValue: number): SetCreditValueAction => ({
    type: SET_CREDIT_VALUE,
    payload: {
        creditValue: creditValue,
    },
});

export type CreditAction = SetCreditValueAction;
