/* eslint-disable @typescript-eslint/no-unused-vars */

import * as React from 'react';
import Button, { ButtonProps } from 'reactstrap/lib/Button';
import { getStrongholdPayClient } from '../../config';
import { addPaymentSourceAction, fetchCustomerTokenAction, selectPaymentSourceAction } from '../../store/actions';
import { connect, MapStateToPropsParam } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { OnExit, AddPaymentSourceOnSuccess, PaymentSource } from '@stronghold/pay-dropin';
import { ApplicationState } from '../../store';
import selectors from '../../store/selectors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUniversity } from '@fortawesome/free-solid-svg-icons/faUniversity';
import Spinner from 'reactstrap/lib/Spinner';
import { ApiKeyModel } from '../../apis';

interface StateProps {
    publishableKey: ApiKeyModel | null;
    customerToken: string;
    isRequestingCustomerToken: boolean;
    integrationId?: string;
    apiVersion: string;
}

interface OwnProps extends ButtonProps {
    onSuccess?: AddPaymentSourceOnSuccess;
    iconColor?: string;
    payLinkId?: string;
}

interface DispatchProps {
    fetchCustomerTokenAction: typeof fetchCustomerTokenAction;
    addPaymentSourceAction: typeof addPaymentSourceAction;
}

type Props = StateProps & OwnProps & DispatchProps;

interface State {
    ready: boolean;
    paymentSource: PaymentSource | null;
}

class AddPaymentSourceButton extends React.Component<Props, State> {
    public state: State = {
        ready: true,
        paymentSource: null,
    };

    onClick = async () => {
        if (!this.props.publishableKey) return;

        this.setState({ ready: false });

        try {
            await this.props.fetchCustomerTokenAction();
        } catch {
            this.setState({ ready: true });
            return;
        }

        // If for whatever reason there is no customer token, throw
        if (!this.props.customerToken) throw new Error('no customer token');

        const client = getStrongholdPayClient(
            this.props.publishableKey.key,
            this.props.publishableKey.environment,
            this.props.apiVersion,
            this.props.integrationId,
        );
        client.addPaymentSource(this.props.customerToken, {
            payLinkCode: this.props.payLinkId,
            onSuccess: this.onSuccess,
            onExit: this.onExit,
            onReady: () => this.setState({ ready: true }),
        });
    };

    onSuccess: AddPaymentSourceOnSuccess = (paymentSource) => {
        this.props.addPaymentSourceAction(paymentSource);
        this.props.selectPaymentSourceAction(paymentSource.id);
        this.setState({ paymentSource });
    };

    onExit: OnExit = () => {
        if (!this.state.ready) {
            this.setState({ ready: true });
        }
        if (this.state.paymentSource && this.props.onSuccess) {
            this.props.onSuccess(this.state.paymentSource);
        }
    };

    render() {
        const {
            children = <React.Fragment>Link a Payment Method</React.Fragment>,
            isRequestingCustomerToken,
            customerToken,
            addSourcesAction,
            fetchCustomerTokenAction,
            showAsLink,
            onExit,
            onSuccess,
            publishableKey,
            addPaymentSourceAction,
            iconColor,
            payLinkId,
            ...props
        } = this.props;
        const { ready } = this.state;

        const color = props.color || 'primary';
        const iconClass = `text-${iconColor || 'white'}`;

        let icon = <FontAwesomeIcon icon={faUniversity} fixedWidth className={iconClass} />;

        const isLoading = !ready;
        const isDisabled = isLoading || props.disabled || !publishableKey;

        if (isLoading) {
            icon = <Spinner type="grow" className={iconClass} size="sm" />;
        }

        return (
            <Button
                {...props}
                color={color}
                onClick={this.onClick}
                disabled={isDisabled}
                data-sh="add-payment-source-button"
            >
                {children}
                <span className="ml-1">{icon}</span>
            </Button>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, OwnProps, ApplicationState> = (state) => ({
    publishableKey: state.global.publishableApiKey,
    customerToken: state.authentication.customerToken || '',
    isRequestingCustomerToken: selectors.global.isActionRequesting(state.global.actions, 'fetch_customer_token'),
    integrationId: state.attribution?.integrationId,
    apiVersion: state.attribution.apiVersion,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            fetchCustomerTokenAction,
            addPaymentSourceAction,
            selectPaymentSourceAction,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(AddPaymentSourceButton);
