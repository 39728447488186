import { Reducer } from 'redux';
import { ApplicationAction } from '../actions';
import { AttributionState } from '../types/attribution';
import { SET_INTEGRATION_ID, SET_API_VERSION } from '../constants';

const unloadedState: AttributionState = {
    integrationId: undefined,
    apiVersion: 'v2',
};

export const attributionReducer: Reducer<AttributionState, ApplicationAction> = (
    state = unloadedState,
    action,
): AttributionState => {
    switch (action.type) {
        case SET_INTEGRATION_ID:
            return {
                ...state,
                integrationId: action.payload.integrationId,
            };
        case SET_API_VERSION:
            return {
                ...state,
                apiVersion: action.payload.apiVersion,
            };
        default:
            return state;
    }
};
