import * as React from 'react';
import NumberInput from './NumberInput';
import { Button } from 'reactstrap';
import selectors from '../store/selectors';
import { TipCustomizationModel } from '../store/types';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';

interface Props {
    originalAmount: number;
    tipModel: TipCustomizationModel;
    amount: number;
    setDefaultValueOnChange?: boolean;
    onSetAmount: (value: number, error: boolean) => void;
}

interface State {
    showAmountField: boolean;
    errorMessage: string | null;
}

export default class TipSelection extends React.Component<Props, State> {
    private tipInput: HTMLInputElement | null = null;

    constructor(props: Props) {
        super(props);
        this.state = {
            showAmountField: false,
            errorMessage: null,
        };
    }

    selectTip = (tipValue: number): void => {
        const amount = this.getAmount(tipValue);
        this.onSetAmount(this.props.amount === amount ? 0 : amount);
    };

    toggleAmountField = async (): Promise<void> => {
        // If we show the amount, we set to zero
        if (this.props.amount === 0) {
            this.props.onSetAmount(0, false);
        } else {
            if (this.state.showAmountField) {
                let tipAmount = this.props.amount;

                // If tip amount does not match a default value, we set the second as a default
                if (
                    this.props.amount !==
                        selectors.number.getTipAmount(
                            this.props.tipModel.type,
                            this.props.tipModel.value1,
                            this.props.originalAmount,
                        ) &&
                    this.props.amount !==
                        selectors.number.getTipAmount(
                            this.props.tipModel.type,
                            this.props.tipModel.value2,
                            this.props.originalAmount,
                        ) &&
                    this.props.amount !==
                        selectors.number.getTipAmount(
                            this.props.tipModel.type,
                            this.props.tipModel.value3,
                            this.props.originalAmount,
                        )
                ) {
                    if (this.props.setDefaultValueOnChange) {
                        tipAmount = selectors.number.getTipAmount(
                            this.props.tipModel.type,
                            this.props.tipModel.value2,
                            this.props.originalAmount,
                        );
                    } else {
                        tipAmount = 0;
                    }
                }
                this.props.onSetAmount(tipAmount, false);
            }
            // If we show the choices, set the default value
            else {
                const tipAmount = this.props.amount;
                this.props.onSetAmount(tipAmount, false);
            }
        }

        await this.setState({
            showAmountField: !this.state.showAmountField,
            errorMessage: null,
        });

        if (this.tipInput && this.state.showAmountField) {
            this.tipInput.focus();
        }
    };

    onSetAmount = (tip: number): void => {
        let errorMessage: string | null = null;
        // We don't use custom percent amounts, it will always be fixed
        if (this.state.showAmountField) {
            if (tip === 0) {
                // Not an error
            } else if (tip < 100) {
                errorMessage = 'Minimum tip amount is $1.00';
            } else if (tip >= 10000) {
                errorMessage = 'Maximum tip amount is $100';
            }
        }

        this.setState({ errorMessage }, () => this.props.onSetAmount(tip, !!errorMessage));
    };

    getAmount = (value: number): number => {
        const { tipModel, originalAmount } = this.props;
        return selectors.number.getTipAmount(tipModel.type, value, originalAmount);
    };
    getAmountStr = (value: number): string => selectors.number.amountToString(this.getAmount(value));

    render() {
        const { amount, tipModel } = this.props;
        const isPercent = tipModel.type === 'percentage';

        const tip1 = tipModel.value1;
        const tip2 = tipModel.value2;
        const tip3 = tipModel.value3;
        let tip1Value: string | null = null;
        let tip2Value: string | null = null;
        let tip3Value: string | null = null;
        let tip1SubValue: string | null = null;
        let tip2SubValue: string | null = null;
        let tip3SubValue: string | null = null;

        if (tipModel.type === 'fixed') {
            tip1Value = selectors.number.amountToString(
                selectors.number.getTipAmount(tipModel.type, tip1, this.props.originalAmount),
            );
            tip2Value = selectors.number.amountToString(
                selectors.number.getTipAmount(tipModel.type, tip2, this.props.originalAmount),
            );
            tip3Value = selectors.number.amountToString(
                selectors.number.getTipAmount(tipModel.type, tip3, this.props.originalAmount),
            );
        }

        if (isPercent) {
            tip1Value = `${Math.round(tip1 / 100)}%`;
            tip2Value = `${Math.round(tip2 / 100)}%`;
            tip3Value = `${Math.round(tip3 / 100)}%`;
            tip1SubValue = selectors.number.amountToString(
                selectors.number.getTipAmount(tipModel.type, tip1, this.props.originalAmount),
            );
            tip2SubValue = selectors.number.amountToString(
                selectors.number.getTipAmount(tipModel.type, tip2, this.props.originalAmount),
            );
            tip3SubValue = selectors.number.amountToString(
                selectors.number.getTipAmount(tipModel.type, tip3, this.props.originalAmount),
            );
        }

        return (
            <div className="text-center" data-sh="tip-selection">
                <div className="d-flex justify-content-center align-items-center mb-4" data-sh="tip-value">
                    {this.state.showAmountField ? (
                        <div className="d-block">
                            <NumberInput
                                innerRef={(input) => {
                                    this.tipInput = input as HTMLInputElement;
                                }}
                                className="mx-auto form-control-tip active"
                                onValueChange={this.onSetAmount}
                                max={100000}
                                value={this.props.amount}
                                prefix={'$'}
                                digits={2}
                            />
                            {this.state.errorMessage ? (
                                <div className="mt-3 font-italic text-danger">{this.state.errorMessage}</div>
                            ) : null}
                        </div>
                    ) : (
                        <Row className="justify-content-center">
                            <Col className="px-2">
                                <Button
                                    onClick={() => this.selectTip(tip1)}
                                    outline={amount !== tip1}
                                    active={amount === this.getAmount(tip1)}
                                    size="lg"
                                    className={'btn-tip'}
                                >
                                    {tip1Value}
                                    {tip1SubValue && <div className="dollar-amount">{tip1SubValue}</div>}
                                </Button>
                            </Col>
                            <Col className="px-2">
                                <Button
                                    onClick={() => this.selectTip(tip2)}
                                    outline={amount !== tip2}
                                    active={amount === this.getAmount(tip2)}
                                    size="lg"
                                    className={'btn-tip'}
                                >
                                    {tip2Value}
                                    {tip2SubValue && <div className="dollar-amount">{tip2SubValue}</div>}
                                </Button>
                            </Col>
                            <Col className="px-2">
                                <Button
                                    onClick={() => this.selectTip(tip3)}
                                    outline={amount !== tip3}
                                    active={amount === this.getAmount(tip3)}
                                    size="lg"
                                    className={'btn-tip'}
                                >
                                    {tip3Value}
                                    {tip3SubValue && <div className="dollar-amount">{tip3SubValue}</div>}
                                </Button>
                            </Col>
                        </Row>
                    )}
                </div>
                <Button className="btn-custom-tip" onClick={this.toggleAmountField} data-sh="tip-custom-button">
                    {!this.state.showAmountField ? 'Enter Custom Amount' : 'Use Predefined Amount'}
                </Button>
            </div>
        );
    }
}
