import { Reducer } from 'redux';
import { ApplicationAction } from '../actions';
import { CreditState } from '../types';
import { SET_CREDIT_VALUE } from '../constants';

const unloadedState: CreditState = {
    creditValue: 0,
};

export const creditReducer: Reducer<CreditState, ApplicationAction> = (state = unloadedState, action): CreditState => {
    switch (action.type) {
        case SET_CREDIT_VALUE:
            return {
                ...state,
                creditValue: action.payload.creditValue,
            };
        default:
            return state;
    }
};
