import * as React from 'react';
import NavMenu from './NavMenu';
import Breadcrumb from 'reactstrap/lib/Breadcrumb';
import BreadcrumbItem from 'reactstrap/lib/BreadcrumbItem';
import { Link } from 'react-router-dom';
import Footer from './Footer';

export interface LayoutBreadcrumbItem {
    href: string;
    title: string | JSX.Element;
}

interface Props {
    navbar?: boolean;
    centered?: boolean;
    className?: string;
    children: JSX.Element | JSX.Element[];
    breadcrumbItems?: LayoutBreadcrumbItem[];
    footer?: boolean;
}

export function LayoutBreadcrumbs(props: { items?: LayoutBreadcrumbItem[] }) {
    const { items = [] } = props;

    if (items.length === 0) return null;

    const isLastItem = (index: number) => index + 1 === items.length;

    return (
        <div className="bg-white border-bottom shadow-sm">
            <div className="mw-lg mx-auto">
                <Breadcrumb listClassName="mb-0">
                    {items.map((value, key) => (
                        <BreadcrumbItem key={key} active={isLastItem(key)}>
                            {isLastItem(key) ? value.title : <Link to={value.href}>{value.title}</Link>}
                        </BreadcrumbItem>
                    ))}
                </Breadcrumb>
            </div>
        </div>
    );
}

export default function Layout(props: Props) {
    const { children, navbar = true, className = '', breadcrumbItems = [], centered = false } = props;

    if (centered) {
        return (
            <div className="d-flex flex-column h-100">
                <div className="flex-fill py-3 container mw-md d-flex align-items-center justify-content-center">
                    {children}
                </div>
                {props.footer !== false && <Footer />}
            </div>
        );
    }

    return (
        <div className="d-flex flex-column h-100">
            {navbar && <NavMenu />}
            <LayoutBreadcrumbs items={breadcrumbItems} />
            <div className={['container', 'mx-auto', 'flex-grow-1', className || 'mw-lg'].join(' ')}>{children}</div>
            <Footer />
        </div>
    );
}
