import React, { KeyboardEvent } from 'react';
import { Input, InputProps } from 'reactstrap';

interface Props extends InputProps {
    className?: string;
    max?: number;
    onValueChange: (value: number) => void;
    value: number;
    prefix?: string;
    suffix?: string;
    digits?: number;
}

const VALID_FIRST = /^[1-9]{1}$/;
const VALID_NEXT = /^[0-9]{1}$/;
const DELETE_KEY_CODE = 8;
const RETURN_KEY_CODE = 13;

export default class NumberInput extends React.Component<Props> {
    handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
        const { value, max, onValueChange } = this.props;
        const { key, keyCode } = e;

        if (keyCode === RETURN_KEY_CODE) {
            e.currentTarget.blur();
        }

        if (
            (value === 0 && !VALID_FIRST.test(key)) ||
            (value !== 0 && !VALID_NEXT.test(key) && keyCode !== DELETE_KEY_CODE)
        ) {
            return;
        }
        const valueString = value.toString();
        let nextValue: number;

        if (keyCode !== DELETE_KEY_CODE) {
            const nextValueString: string = value === 0 ? key : `${valueString}${key}`;
            nextValue = Number.parseInt(nextValueString, 10);
        } else {
            const nextValueString = valueString.slice(0, -1);
            nextValue = nextValueString === '' ? 0 : Number.parseInt(nextValueString, 10);
        }

        if (max && nextValue > max) {
            return;
        }

        onValueChange(nextValue);
    };

    valueDisplay = () => {
        const value = this.props.value / Math.pow(10, this.props.digits || 0);
        return `${this.props.prefix || ''}${value.toFixed(this.props.digits || 0)}${this.props.suffix || ''}`;
    };

    render() {
        const { className, value, ...props } = this.props;
        const valueAbsTrunc = Math.trunc(Math.abs(value));

        if (value !== valueAbsTrunc || !Number.isFinite(value) || Number.isNaN(value)) {
            throw new Error(`invalid value property`);
        }
        return (
            <Input
                {...props}
                className={className}
                inputMode="numeric"
                onKeyDown={this.handleKeyDown}
                value={this.valueDisplay()}
            />
        );
    }
}
