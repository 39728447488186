import 'react';
import * as React from 'react';
import { Redirect, Route, RouteComponentProps, RouteProps, withRouter } from 'react-router-dom';
import { connect, MapStateToPropsParam } from 'react-redux';
import { ApplicationState } from '../store';
import { bindActionCreators, Dispatch } from 'redux';
import { fetchProfileAction } from '../store/actions';
import { ROUTE } from '../config';
import selectors from '../store/selectors';

interface StateProps {
    isAuthenticated: boolean;
    hasPaymentSource: boolean;
    hasCharge: boolean;
}

type OwnProps = RouteComponentProps &
    RouteProps & {
        authenticate: boolean;
        allowUnauthenticated?: boolean;
        paymentSourceRequired?: boolean;
        isMerchantRoute?: boolean;
    };

interface DispatchProps {
    fetchProfileAction: typeof fetchProfileAction;
}

type Props = StateProps & OwnProps & DispatchProps;

type State = {
    ready: boolean;
};

class PortalRoute extends React.PureComponent<Props, State> {
    public state: State = {
        ready: false,
    };

    async componentDidMount() {
        try {
            if (this.props.authenticate) {
                await this.props.fetchProfileAction();
            }
        } catch {
            // Error
        } finally {
            this.setState({ ready: true });
        }
    }

    public render() {
        const {
            isAuthenticated,
            authenticate,
            allowUnauthenticated = false,
            isMerchantRoute = false,
            hasPaymentSource,
            hasCharge,
            paymentSourceRequired = false,
        } = this.props;
        const { ready } = this.state;
        if (!ready) {
            return null;
        }

        // If authentication asked and mandatory and the user is not authenticated
        // We redirect to the login page
        if (authenticate && !allowUnauthenticated && !isAuthenticated) {
            return <Redirect to={ROUTE.SIGN_UP} />;
        }

        // If the payment sources are required and the customer do not have a payment source
        // We redirect to the link account page
        if (isAuthenticated && !hasPaymentSource && paymentSourceRequired && !hasCharge) {
            return <Redirect to={ROUTE.LINK_ACCOUNT} />;
        }

        if (isMerchantRoute && !selectors.global.isMerchantRoute()) {
            window.location.href = 'https://strongholdpay.com';
            return null;
        }

        return <Route {...this.props} />;
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, OwnProps, ApplicationState> = (state) => ({
    isAuthenticated: state.authentication.isAuthenticated,
    hasPaymentSource: state.paymentSources.arr.length > 0,
    hasCharge: state.charge.list.items.length > 0,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            fetchProfileAction,
        },
        dispatch,
    );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PortalRoute));
