import * as React from 'react';
import { connect, MapStateToPropsParam } from 'react-redux';
import { ApplicationState } from '../store';

interface StateProps {
    imageUrl: string | null;
    displayName: string;
}

interface OwnProps extends React.HTMLAttributes<HTMLDivElement> {
    maxHeight?: number;
}

type Props = StateProps & OwnProps;

function MerchantImg(props: Props) {
    const { imageUrl, displayName, maxHeight = 170, className = '' } = props;

    if (!imageUrl) {
        return null;
    }

    return (
        <img
            src={imageUrl}
            alt={displayName}
            className={['img-responsive', className].join(' ')}
            style={{ maxHeight }}
        />
    );
}

const mapStateToProps: MapStateToPropsParam<StateProps, OwnProps, ApplicationState> = (state) => ({
    imageUrl: state.global.merchant.image_url,
    displayName: state.global.merchant.display_name,
});

export default connect(mapStateToProps)(MerchantImg);
