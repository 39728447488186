import { TipType } from '../types';

export function amountToString(amount: number | null) {
    const value = ((amount || 0) / 100).toFixed(2);

    return `$${value}`;
}

export function getTipAmount(tipType: TipType, tipValue: number, originalAmount: number) {
    // If fixed amount, return the value directly
    if (tipType === 'fixed') {
        return tipValue;
    }

    // Percent
    else if (tipType === 'percentage') {
        return Math.round((originalAmount * tipValue) / 10000);
    }

    throw new Error(`Tip type ${tipType} not implemented`);
}

export function getAmountDetails(data: {
    subAmount: number;
    totalAmount: number;
    taxAmount: number;
    convenienceFee?: number;
    processingFee?: number;
    tipAmount?: number;
    tipType: TipType;
    creditValue?: number;
}) {
    const {
        tipType,
        tipAmount = 0,
        convenienceFee = 0,
        processingFee = 0,
        taxAmount,
        totalAmount,
        subAmount,
        creditValue = 0,
    } = data;

    const totalAmountWithFees = totalAmount + convenienceFee;
    const fullAmount = totalAmountWithFees + tipAmount + processingFee - creditValue;

    return {
        totalAmount,
        taxAmount,
        convenienceFee,
        processingFee,
        subAmount,
        tipAmount,
        tipType,
        totalAmountWithFees,
        // Prevent charge amount going below zero
        fullAmount: fullAmount > 0 ? fullAmount : 0,
        creditValue: creditValue,
    };
}
