import { ActionName, ConfigurationStatus, HeaderTitle } from '../types';
import {
    RESET_ACTION_STATUS,
    SET_ACTION_REQUEST_STATUS,
    SET_ACTION_RESPONSE_ERROR,
    SET_CONFIGURATION,
    SET_CONFIGURATION_STATUS,
    SET_ERROR_MESSAGE,
    UPDATE_HEADER_TITLE,
} from '../constants';
import { AppThunkAction } from '../index';
import { ApplicationAction } from './index';
import { setConfiguration } from '../../config';
import { ConfigurationModel, getConfiguration, ResponseErrorCode } from '../../apis';
import { ResponseError } from '../../apis';
import { LocationChangeAction } from 'connected-react-router';

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

export interface SetActionRequestingAction {
    type: SET_ACTION_REQUEST_STATUS;
    payload: {
        name: ActionName;
        isRequesting: boolean;
    };
}

export function setActionRequestingAction(name: ActionName, isRequesting: boolean): SetActionRequestingAction {
    return {
        type: SET_ACTION_REQUEST_STATUS,
        payload: {
            name,
            isRequesting,
        },
    };
}

export interface SetActionResponseErrorAction {
    type: SET_ACTION_RESPONSE_ERROR;
    payload: {
        name: ActionName;
        error: ResponseError;
    };
}

export function setActionResponseErrorAction(name: ActionName, error: ResponseError): SetActionResponseErrorAction {
    return {
        type: SET_ACTION_RESPONSE_ERROR,
        payload: {
            name,
            error,
        },
    };
}

export interface SetErrorMessageAction {
    type: SET_ERROR_MESSAGE;
    payload: {
        errorCode: ResponseErrorCode;
    };
}

export function setErrorMessageAction(errorCode: ResponseErrorCode = ResponseErrorCode.Unknown): SetErrorMessageAction {
    return {
        type: SET_ERROR_MESSAGE,
        payload: {
            errorCode,
        },
    };
}

export interface ResetActionStatusAction {
    type: RESET_ACTION_STATUS;
    payload: {
        name: ActionName;
    };
}

export function resetActionStatusAction(name: ActionName): ResetActionStatusAction {
    return {
        type: RESET_ACTION_STATUS,
        payload: {
            name,
        },
    };
}

export interface SetConfigurationAction {
    type: SET_CONFIGURATION;
    payload: {
        configuration: ConfigurationModel;
    };
}

export function setConfigurationAction(configuration: ConfigurationModel): SetConfigurationAction {
    return {
        type: SET_CONFIGURATION,
        payload: {
            configuration,
        },
    };
}

export interface SetConfigurationStatusAction {
    type: SET_CONFIGURATION_STATUS;
    payload: { status: ConfigurationStatus };
}

export function setConfigurationStatusAction(status: ConfigurationStatus): SetConfigurationStatusAction {
    return {
        type: SET_CONFIGURATION_STATUS,
        payload: { status },
    };
}

export interface UpdateHeaderTitleAction {
    type: UPDATE_HEADER_TITLE;
    payload: {
        headerPartial: Partial<HeaderTitle>;
    };
}

export function updateHeaderTitleAction(headerPartial: Partial<HeaderTitle>): UpdateHeaderTitleAction {
    return {
        type: UPDATE_HEADER_TITLE,
        payload: {
            headerPartial,
        },
    };
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type GlobalAction =
    | SetActionRequestingAction
    | SetActionResponseErrorAction
    | ResetActionStatusAction
    | SetConfigurationAction
    | SetConfigurationStatusAction
    | SetErrorMessageAction
    | UpdateHeaderTitleAction
    | LocationChangeAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export function fetchConfiguration(): AppThunkAction<ApplicationAction> {
    return async (dispatch) => {
        dispatch(setActionRequestingAction('fetch_configuration', true));
        try {
            const result = await getConfiguration();

            setConfiguration(result);
            dispatch(setConfigurationAction(result));
        } catch (e) {
            dispatch(setConfigurationStatusAction('error'));
            dispatch(setErrorMessageAction());
        }

        dispatch(setActionRequestingAction('fetch_configuration', false));
    };
}
