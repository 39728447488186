import * as React from 'react';
import { PayLinkTipModel, MerchantModel } from '../apis';
import selectors from '../store/selectors';

interface Props {
    tipData: PayLinkTipModel;
    merchant: MerchantModel;
    orderAmount?: number;
    orderCreatedAt?: string;
}

export default function TipMessage(props: Props) {
    const { tipData, orderAmount, orderCreatedAt } = props;
    let forOrder = null;

    let budtender = (
        <React.Fragment>
            <div
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}
                className="mt-3"
            >
                <div>Tips go directly to our employees. Thank you for shopping with us.</div>
            </div>
        </React.Fragment>
    );
    if (tipData.details.display_message) {
        budtender = (
            <div className="mb-2" data-sh="message">
                {tipData.details.display_message}
            </div>
        );
    }
    if (orderAmount) {
        const originalAmountStr = selectors.number.amountToString(orderAmount);
        forOrder = (
            <small className="text-muted">
                for {originalAmountStr}
                {orderCreatedAt ? <span> on {selectors.format.formatDateTimeMinutes(orderCreatedAt)}</span> : null}
            </small>
        );
    }

    return (
        <div data-sh="tip-message">
            {budtender}
            {forOrder}
        </div>
    );
}
