import * as api from '../../apis';
import {
    ADD_PAYMENT_SOURCE,
    REMOVE_PAYMENT_SOURCE,
    SELECT_PAYMENT_SOURCE,
    SET_PAYMENT_SOURCES,
    UPDATE_PAYMENT_SOURCE,
    IS_NEW_PAYMENT_SOURCE_LINKED,
} from '../constants';
import { setActionRequestingAction, setActionResponseErrorAction } from './global';
import { AppThunkAction } from '../index';
import { ApplicationAction } from './index';
import { ResponseError } from '../../apis';
import { PaymentSource } from '@stronghold/pay-dropin';

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

export interface SetPaymentSourcesAction {
    type: typeof SET_PAYMENT_SOURCES;
    payload: {
        arr: PaymentSource[];
    };
}

export const setPaymentSourcesAction = (arr: PaymentSource[]): SetPaymentSourcesAction => ({
    type: SET_PAYMENT_SOURCES,
    payload: {
        arr,
    },
});

export interface AddPaymentSourceAction {
    type: typeof ADD_PAYMENT_SOURCE;
    payload: {
        data: PaymentSource;
    };
}

export const addPaymentSourceAction = (data: PaymentSource): AddPaymentSourceAction => ({
    type: ADD_PAYMENT_SOURCE,
    payload: {
        data,
    },
});

export interface UpdatePaymentSourceAction {
    type: typeof UPDATE_PAYMENT_SOURCE;
    payload: {
        data: PaymentSource;
    };
}

export const updatePaymentSourceAction = (data: PaymentSource): UpdatePaymentSourceAction => ({
    type: UPDATE_PAYMENT_SOURCE,
    payload: {
        data,
    },
});

export interface RemovePaymentSourceAction {
    type: typeof REMOVE_PAYMENT_SOURCE;
    payload: {
        paymentSourceId: string;
    };
}

export const removePaymentSourceAction = (paymentSourceId: string): RemovePaymentSourceAction => ({
    type: REMOVE_PAYMENT_SOURCE,
    payload: { paymentSourceId },
});

export interface SelectPaymentSourceAction {
    type: typeof SELECT_PAYMENT_SOURCE;
    payload: { paymentSourceId: string };
}

export const selectPaymentSourceAction = (paymentSourceId: string): SelectPaymentSourceAction => ({
    type: SELECT_PAYMENT_SOURCE,
    payload: { paymentSourceId },
});

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type PaymentSourcesAction =
    | SetPaymentSourcesAction
    | AddPaymentSourceAction
    | UpdatePaymentSourceAction
    | RemovePaymentSourceAction
    | SelectPaymentSourceAction
    | IsNewPaymentSourceLinkedAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export interface FetchPaymentSourcesActionOptions {
    customerId?: string;
    active?: boolean;
}

export function deactivatePaymentSourceAction(sourceId: string): AppThunkAction<ApplicationAction> {
    return async (dispatch) => {
        await dispatch(setActionRequestingAction('update_payment_source', true));
        try {
            const result = await api.deactivatePaymentSource(sourceId);
            await dispatch(updatePaymentSourceAction(result));
        } catch (e) {
            if (e instanceof ResponseError) {
                dispatch(setActionResponseErrorAction('update_payment_source', e));
            }
            throw e;
        } finally {
            await dispatch(setActionRequestingAction('update_payment_source', false));
        }
    };
}

export function fetchPaymentSourcesAction(): AppThunkAction<ApplicationAction> {
    return async (dispatch) => {
        await dispatch(setActionRequestingAction('fetch_payment_sources', true));
        try {
            const result = await api.fetchPaymentSources();
            await dispatch(setPaymentSourcesAction(result.items));
        } catch (e) {
            if (e instanceof ResponseError) {
                dispatch(setActionResponseErrorAction('fetch_payment_sources', e));
            }
        } finally {
            await dispatch(setActionRequestingAction('fetch_payment_sources', false));
        }
    };
}

interface IsNewPaymentSourceLinkedAction {
    type: typeof IS_NEW_PAYMENT_SOURCE_LINKED;
    payload: {
        isNewPaymentSourceLinked: boolean;
    };
}

export function isNewPaymentSourceLinkedAction(isNewPaymentSourceLinked: boolean): IsNewPaymentSourceLinkedAction {
    return {
        type: IS_NEW_PAYMENT_SOURCE_LINKED,
        payload: { isNewPaymentSourceLinked },
    };
}
