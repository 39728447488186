import * as React from 'react';
import { connect, MapStateToPropsParam } from 'react-redux';
import { ApplicationState } from '../../store';
import { bindActionCreators, Dispatch } from 'redux';
import Card from 'reactstrap/lib/Card';
import CardBody from 'reactstrap/lib/CardBody';
import {
    setErrorMessageAction,
    updatePayLinkAction,
    updateHeaderTitleAction,
    selectPaymentSourceAction,
} from '../../store/actions';
import { PayLinkModel } from '../../apis';
import { PaymentSource } from '@stronghold/pay-dropin';
import StrongholdPay from '../../components/StrongholdPay';
import CardHeader from 'reactstrap/lib/CardHeader';
import AddPaymentSourceButton from '../../components/dropin/AddPaymentSourceButton';
import PaymentSourceList from '../../components/PaymentSourceList';
import PayLinkSuccessCard from '../../components/PayLinkSuccessCard';
import ToggleSplit from '../../components/split/ToggleSplit';
import { SPLIT_FLAG } from '../../store/types';
import Merchant from '../../components/Merchant';

interface StateProps {
    paymentSources: PaymentSource[];
    isUsed: boolean;
}

interface OwnProps {
    link: PayLinkModel;
}

interface DispatchProps {
    setErrorMessageAction: typeof setErrorMessageAction;
    updateHeaderTitleAction: typeof updateHeaderTitleAction;
    updatePayLinkAction: typeof updatePayLinkAction;
    selectPaymentSourceAction: typeof selectPaymentSourceAction;
}

type Props = StateProps & OwnProps & DispatchProps;

class BankLinkPage extends React.Component<Props> {
    async componentDidMount() {
        await this.props.updateHeaderTitleAction({
            values: ['Bank Link'],
        });
    }

    render() {
        const { isUsed } = this.props;

        if (isUsed) {
            return (
                <PayLinkSuccessCard title="Success">
                    <div>
                        Your bank account has been linked successfully and you are{' '}
                        <strong>ready to make payments.</strong>
                    </div>
                </PayLinkSuccessCard>
            );
        }

        let paymentMethods = <></>;

        if (this.props.paymentSources.length > 0) {
            paymentMethods = (
                <Card>
                    <CardHeader>Linked Payment Methods</CardHeader>
                    <PaymentSourceList canAddPaymentSource={false} flush={true} />
                </Card>
            );
        }

        return (
            <>
                <Card className="mt-3">
                    <CardBody className="p-md-5 text-center">
                        <ToggleSplit flag={SPLIT_FLAG.SIMPLE_CHECKOUT} unactiveComponent={<Merchant />}>
                            <StrongholdPay />
                        </ToggleSplit>
                        <br />
                    </CardBody>
                </Card>
                {paymentMethods}
                <Card>
                    <CardBody className="p-md-5 text-center">
                        <div className="mb-3">
                            Link a bank account to make payments with{' '}
                            <strong>{this.props.link.merchant.display_name}</strong>.
                        </div>
                        <AddPaymentSourceButton
                            onSuccess={(source) => {
                                this.props.updatePayLinkAction({ status: 'used' });
                                this.props.selectPaymentSourceAction(source.id);
                            }}
                            payLinkId={this.props.link.id}
                            block
                            size="lg"
                        />
                    </CardBody>
                </Card>
            </>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, OwnProps, ApplicationState> = (state, ownProps) => ({
    paymentSources: state.paymentSources.arr,
    isUsed: ownProps.link.status === 'used',
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            setErrorMessageAction,
            updateHeaderTitleAction,
            updatePayLinkAction,
            selectPaymentSourceAction,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(BankLinkPage);
