import { SET_INTEGRATION_ID, SET_API_VERSION } from '../constants';

export interface SetIntegrationIdAction {
    type: SET_INTEGRATION_ID;
    payload: {
        integrationId: string;
    };
}

export const setIntegrationIdAction = (integrationId: string): SetIntegrationIdAction => ({
    type: SET_INTEGRATION_ID,
    payload: {
        integrationId: integrationId,
    },
});

export interface SetApiVersionAction {
    type: SET_API_VERSION;
    payload: {
        apiVersion: string;
    };
}

export const setApiVersionAction = (apiVersion: string): SetApiVersionAction => ({
    type: SET_API_VERSION,
    payload: {
        apiVersion: apiVersion,
    },
});

export type AttributionAction = SetIntegrationIdAction | SetApiVersionAction;
