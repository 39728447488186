const MESSAGE = {
    ERROR_COMMON_TITLE: 'Sorry, something went wrong',
    ERROR_COMMON: 'We weren’t able to process your request. Please go back and try again later.',
    CANCELED_LINK_TITLE: 'Canceled Link',
    CANCELED_LINK:
        'This link to pay is no longer active. Please contact the merchant for another way to make your payment.',
    ORDER_NOT_FOUND_TITLE: 'Page Not Found',
    ORDER_NOT_FOUND: `We had a problem finding the associated page. Please try again in a few minutes.`,
    ORDER_AMOUNT_MODIFIED_TITLE: 'Order Total has changed',
    ORDER_AMOUNT_MODIFIED:
        "Your order total has changed. Please reload and make sure you're aware of the changes before completing your order.",
    EXPIRED_LINK_TITLE: 'Expired Link',
    EXPIRED_LINK_SUB_TITLE: 'The link has expired and can no longer be used.',
    EXPIRED_LINK: 'Please call the merchant and ask them to send you a new link.',
    PAYMENT_METHOD_SELECT_TITLE: 'Select a payment method',
    PAYMENT_METHOD_CHANGE: 'Change your payment method',
    PAYMENT_METHOD_NONE: 'In order to complete a payment, you must securely link a payment method.',
    PAYMENT_METHOD_NEEDED: 'You need to select an account to complete your payment.',
    ERROR_MERCHANT_SOFTWARE_TITLE: 'Merchant Software Error',
    ERROR_MERCHANT_SOFTWARE:
        'We are unable to process your payment due to an error with the software the merchant uses.',
    ERROR_INVALID_CONFIGURATION_TITLE: 'Configuration problem',
    ERROR_INVALID_CONFIGURATION:
        'Oops, it looks like the Portal configuration is wrong. Please check the credentials used.',
    ERROR_LIVE_NOT_APPROVED_TITLE: 'Live approval required',
    ERROR_LIVE_NOT_APPROVED:
        "Your Stronghold account has not been fully approved. Please use test credentials or contact Stronghold's onboarding team.",
    ERROR_USERNAME_NOT_FOUNT: (identifier?: 'mobile' | 'email') =>
        `We couldn't find any payments made with this ${identifier || 'identifier'}.`,
    ERROR_INCORRECT_CODE: 'Incorrect code.',
    CHECK_AND_TRY_AGAIN: 'Please check and try again.',
    SELECT_OR_ADD_TIP: 'Choose a suggested tip amount or add a custom amount:',
    INVALID_CHARGE_STATUS_TITLE: 'Charge Status Error',
    INVALID_CHARGE_STATUS:
        'The charge associated with this link is not in a payable state so we have canceled this link. Please contact the merchant for another way to make your payment.',
};

export default MESSAGE;
