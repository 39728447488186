import React from 'react';
import { PayLinkModel, PayLinkOrderModel } from '../apis';
import TableBody from './TableBody';
import Table from 'reactstrap/lib/Table';
import selectors from '../store/selectors';

interface StateProps {
    order: PayLinkOrderModel;
}

interface OwnProps {
    link: PayLinkModel | null;
}
type Props = StateProps & OwnProps;

export default class UploadedDocuments extends React.Component<Props> {
    render() {
        return (
            <div>
                {this.props.order.documents && (
                    <Table hover responsive className="table-sm">
                        <thead>
                            <tr>
                                <th style={{ minWidth: 140 }}>Name</th>
                                <th>Size</th>
                                <th>Link</th>
                            </tr>
                        </thead>
                        <TableBody isLoading={false} rows={this.props.order.documents.length} columns={3}>
                            {this.props.order.documents.map((document, index) => (
                                <tr key={index}>
                                    <td>{document.file_name}</td>
                                    <td>{`${selectors.format.formatKB(document.file_size)} KB`}</td>
                                    <td>
                                        <a href={document.url} download={document.url} target="_blank" rel="noreferrer">
                                            Download
                                        </a>
                                    </td>
                                </tr>
                            ))}
                        </TableBody>
                    </Table>
                )}
            </div>
        );
    }
}
