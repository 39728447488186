import { request } from './utils';
import { ListSegment } from './response';
import { PaymentSource } from '@stronghold/pay-dropin';

export async function fetchPaymentSources() {
    const res = await request<ListSegment<PaymentSource>>({
        method: 'GET',
        url: `/api/payment-sources`,
    });
    return res.result;
}

export async function deactivatePaymentSource(sourceId: string) {
    const res = await request<PaymentSource>({
        method: 'DELETE',
        url: `/api/payment-sources/${sourceId}`,
    });
    return res.result;
}
