import moment from 'moment';

// Date
export const formatDateTimeMinutes = (value: string | null) => (value ? moment(value).format('MMM DD h:mm a') : null);
export const formatDate = (value: string | null) => (value ? moment(value).format('MMM DD') : null);

// Currency
export function formatAmount(amount: number | string) {
    const num = typeof amount === 'string' ? parseInt(amount) : amount;
    const value = ((num || 0) / 100).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    return `$${value}`;
}

// Bytes
export function formatKB(size: number) {
    return Math.round(size / 1024).toFixed(2);
}
