import { PayLinkState } from '../types';
import { DeepPartial, Reducer } from 'redux';
import { ApplicationAction } from '../actions';
import { SET_PAY_LINK, UPDATE_PAY_LINK } from '../constants';
import { merge } from 'lodash';
import { PayLinkModel } from '../../apis';

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: PayLinkState = {
    data: null,
};

export const payLinkReducer: Reducer<PayLinkState, ApplicationAction> = (
    state = unloadedState,
    action,
): PayLinkState => {
    switch (action.type) {
        case SET_PAY_LINK:
            return {
                ...state,
                data: action.payload.link,
            };
        case UPDATE_PAY_LINK:
            if (!state.data) {
                return state;
            }
            return {
                ...state,
                data: merge<DeepPartial<PayLinkModel>, PayLinkModel, DeepPartial<PayLinkModel>>(
                    {},
                    state.data,
                    action.payload.payLink,
                ),
            };
        default:
            return state;
    }
};
