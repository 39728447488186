import * as React from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLifeRing } from '@fortawesome/free-solid-svg-icons/faLifeRing';

export default function SupportCard() {
    return (
        <Card>
            <CardHeader>
                <div className="d-flex align-items-center">
                    <FontAwesomeIcon size={'lg'} icon={faLifeRing} className="mr-2" />
                    <div>Support</div>
                </div>
            </CardHeader>
            <CardBody>
                Need Help? Email{' '}
                <a href="mailto:happiness@stronghold.co?Subject=Assistance - Stronghold Pay">happiness@stronghold.co</a>{' '}
                for assistance.
            </CardBody>
        </Card>
    );
}
