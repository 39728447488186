import * as api from '../../apis';
import { ChargeModel, ListSegment, ResponseError } from '../../apis';
import { CLEAN_CHARGES, SET_CHARGES } from '../constants';
import { setActionRequestingAction, setActionResponseErrorAction } from './global';
import { AppThunkAction } from '../index';
import { ApplicationAction } from './index';

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

export interface SetChargesAction {
    type: SET_CHARGES;
    payload: {
        list: ListSegment<ChargeModel>;
    };
}

export const setChargesAction = (list: ListSegment<ChargeModel>): SetChargesAction => ({
    type: SET_CHARGES,
    payload: {
        list,
    },
});

export interface CleanChargesAction {
    type: CLEAN_CHARGES;
}

export const cleanChargesAction = (): CleanChargesAction => ({
    type: CLEAN_CHARGES,
});

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type ChargeAction = SetChargesAction | CleanChargesAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
export interface FetchChargesActionOptions {
    search?: string;
    settlementId?: string;
    skip?: number;
    limit?: number;
    status?: string[];
    from?: string;
    to?: string;
}

export function fetchChargesAction(options: FetchChargesActionOptions = {}): AppThunkAction<ApplicationAction> {
    return async (dispatch, getState) => {
        const customerId = getState().authentication.customer.id;
        if (!customerId) return;

        await dispatch(setActionRequestingAction('fetch_charges', true));

        try {
            const body = await api.listCharges(options);
            await dispatch(setChargesAction(body));
        } catch (e) {
            if (e instanceof ResponseError) {
                dispatch(setActionResponseErrorAction('fetch_charges', e));
            }
        } finally {
            await dispatch(setActionRequestingAction('fetch_charges', false));
        }
    };
}
