import { ChargeState } from '../types';
import { CLEAN_CHARGES, SET_CHARGES } from '../constants';
import { Reducer } from 'redux';
import { ApplicationAction } from '../actions';
import { segmentListLimit } from '../../apis';

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: ChargeState = {
    list: {
        items: [],
        limit: segmentListLimit,
        skipped: 0,
        total: 0,
    },
};

export const chargeReducer: Reducer<ChargeState, ApplicationAction> = (state = unloadedState, action): ChargeState => {
    switch (action.type) {
        case SET_CHARGES:
            return {
                ...state,
                list: action.payload.list,
            };
        case CLEAN_CHARGES:
            return {
                ...state,
                list: unloadedState.list,
            };
        default:
            return state;
    }
};
