import * as React from 'react';
import Powered from './Powered';
import Container from 'reactstrap/lib/Container';

export default function Footer() {
    return (
        <div className="w-100">
            <Container className="p-3 p-md-4 text-center">
                <Powered isBlack={true} />
            </Container>
        </div>
    );
}
