import * as React from 'react';
import selectors from '../../store/selectors';
import { TipType } from '../../store/types';

export interface Props {
    subAmount: number;
    taxAmount: number;
    convenienceFee: number;
    processingFee: number;
    totalAmount: number;
    tipValue?: number;
    tipType: TipType;
    creditValue?: number;
}

export default function CheckoutTotal(props: Props) {
    const amountDetails = selectors.number.getAmountDetails({
        convenienceFee: props.convenienceFee,
        processingFee: props.processingFee,
        taxAmount: props.taxAmount,
        totalAmount: props.totalAmount,
        subAmount: props.subAmount,
        tipAmount: props.tipValue,
        tipType: props.tipType,
        creditValue: props.creditValue,
    });

    const taxAmountStr = amountDetails.taxAmount ? selectors.number.amountToString(amountDetails.taxAmount) : null;
    const convenienceFeeStr =
        amountDetails.convenienceFee || amountDetails.processingFee
            ? selectors.number.amountToString(amountDetails.convenienceFee + amountDetails.processingFee)
            : null;
    const subAmountStr = selectors.number.amountToString(amountDetails.subAmount);
    const tipAmountStr = amountDetails.tipAmount ? selectors.number.amountToString(amountDetails.tipAmount) : null;
    const creditAmountStr = amountDetails.creditValue
        ? selectors.number.amountToString(amountDetails.creditValue)
        : null;
    const fullAmountStr = selectors.number.amountToString(amountDetails.fullAmount);

    return (
        <div data-sh="order-amounts">
            <div className="d-flex justify-content-between h6">
                <div className="text-muted">Subtotal</div>
                <div className="text-muted" data-sh="sub-amount">
                    {subAmountStr}
                </div>
            </div>
            {taxAmountStr ? (
                <div className="d-flex justify-content-between h6">
                    <div className="text-muted">Tax</div>
                    <div className="text-muted" data-sh="tax-amount">
                        {taxAmountStr}
                    </div>
                </div>
            ) : null}
            {convenienceFeeStr ? (
                <div className="d-flex justify-content-between h6">
                    <div className="text-muted">Convenience Fee</div>
                    <div className="text-muted" data-sh="convenience-fee-amount">
                        {convenienceFeeStr}
                    </div>
                </div>
            ) : null}
            {tipAmountStr ? (
                <div className="d-flex justify-content-between h6 font-weight-bold">
                    <div className="text-muted">Tip</div>
                    <div className="text-muted" data-sh="tip-amount">
                        {tipAmountStr}
                    </div>
                </div>
            ) : null}
            {creditAmountStr ? (
                <div className="d-flex justify-content-between h6">
                    <div className="text-muted">Credit Amount</div>
                    <div className="text-muted" data-sh="credit-amount">
                        ({creditAmountStr})
                    </div>
                </div>
            ) : null}
            <div className="d-flex justify-content-between h5 font-weight-bolder">
                <div className="text">Total</div>
                <div className="text" data-sh="amount">
                    {fullAmountStr}
                </div>
            </div>
        </div>
    );
}
