export const SET_ACTION_REQUEST_STATUS = 'SET_ACTION_REQUEST_STATUS';
export type SET_ACTION_REQUEST_STATUS = typeof SET_ACTION_REQUEST_STATUS;
export const SET_ACTION_RESPONSE_ERROR = 'SET_ACTION_RESPONSE_ERROR';
export type SET_ACTION_RESPONSE_ERROR = typeof SET_ACTION_RESPONSE_ERROR;
export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';
export type SET_ERROR_MESSAGE = typeof SET_ERROR_MESSAGE;
export const RESET_ACTION_STATUS = 'RESET_ACTION_STATUS';
export type RESET_ACTION_STATUS = typeof RESET_ACTION_STATUS;
export const SET_CONFIGURATION = 'SET_CONFIGURATION';
export type SET_CONFIGURATION = typeof SET_CONFIGURATION;
export const SET_CONFIGURATION_STATUS = 'SET_CONFIGURATION_STATUS';
export type SET_CONFIGURATION_STATUS = typeof SET_CONFIGURATION_STATUS;
export const UPDATE_HEADER_TITLE = 'UPDATE_HEADER_TITLE';
export type UPDATE_HEADER_TITLE = typeof UPDATE_HEADER_TITLE;
export const LOCATION_CHANGE = '@@router/LOCATION_CHANGE';
export type LOCATION_CHANGE = typeof LOCATION_CHANGE;

// Authentication
export const SET_TOKEN = 'SET_TOKEN';
export type SET_TOKEN = typeof SET_TOKEN;
export const IS_AUTHENTICATED = 'IS_AUTHENTICATED';
export type IS_AUTHENTICATED = typeof IS_AUTHENTICATED;
export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER';
export type UPDATE_CUSTOMER = typeof UPDATE_CUSTOMER;

// Payment sources
export const ADD_PAYMENT_SOURCE = 'ADD_PAYMENT_SOURCE';
export const SET_PAYMENT_SOURCES = 'SET_PAYMENT_SOURCES';
export const UPDATE_PAYMENT_SOURCE = 'UPDATE_PAYMENT_SOURCE';
export const REMOVE_PAYMENT_SOURCE = 'REMOVE_PAYMENT_SOURCE';
export const SELECT_PAYMENT_SOURCE = 'SELECT_PAYMENT_SOURCE';
export const IS_NEW_PAYMENT_SOURCE_LINKED = 'IS_NEW_PAYMENT_SOURCE_LINKED';

// Pay Link
export const SET_PAY_LINK = 'SET_PAY_LINK';
export type SET_PAY_LINK = typeof SET_PAY_LINK;
export const UPDATE_PAY_LINK = 'UPDATE_PAY_LINK';

export const SET_PAY_LINK_CUSTOMER = 'SET_PAY_LINK_CUSTOMER';
export type SET_PAY_LINK_CUSTOMER = typeof SET_PAY_LINK_CUSTOMER;

// Customer
export const SET_CUSTOMER_TOKEN = 'SET_CUSTOMER_TOKEN';
export type SET_CUSTOMER_TOKEN = typeof SET_CUSTOMER_TOKEN;

// Charges
export const SET_CHARGES = 'SET_CHARGES';
export type SET_CHARGES = typeof SET_CHARGES;
export const CLEAN_CHARGES = 'CLEAN_CHARGES';
export type CLEAN_CHARGES = typeof CLEAN_CHARGES;

// Credits
export const SET_CREDIT_VALUE = 'SET_CREDIT_VALUE';
export type SET_CREDIT_VALUE = typeof SET_CREDIT_VALUE;

// Attribution
export const SET_INTEGRATION_ID = 'SET_INTEGRATION_ID';
export type SET_INTEGRATION_ID = typeof SET_INTEGRATION_ID;
export const SET_API_VERSION = 'SET_API_VERSION';
export type SET_API_VERSION = typeof SET_API_VERSION;
