/* eslint-disable @typescript-eslint/no-unused-vars */

import * as React from 'react';
import Button from 'reactstrap/lib/Button';
import { getStrongholdPayClient } from '../../config';
import { fetchCustomerTokenAction, setActionRequestingAction } from '../../store/actions';
import { connect, MapStateToPropsParam } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ERROR_CODE, OnError, OnExit, TipDropin, TipOnSuccess } from '@stronghold/pay-dropin';
import { ApplicationState } from '../../store';
import selectors from '../../store/selectors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Spinner from 'reactstrap/lib/Spinner';
import { ButtonProps } from 'reactstrap';
import { faMoneyBill } from '@fortawesome/free-solid-svg-icons/faMoneyBill';
import { ApiKeyModel } from '../../apis';

interface StateProps {
    publishableKey: ApiKeyModel | null;
    customerToken: string;
    noSource: boolean;
    isAuthorizingPayment: boolean;
    integrationId?: string;
    apiVersion: string;
}

interface OwnProps extends ButtonProps {
    payLinkCode: string;
    tip: TipDropin;
    onSuccess: TipOnSuccess;
}

interface DispatchProps {
    fetchCustomerTokenAction: typeof fetchCustomerTokenAction;
    setActionRequestingAction: typeof setActionRequestingAction;
}

type Props = StateProps & OwnProps & DispatchProps;

interface State {
    ready: boolean;
}

class AddTipDropinButton extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = { ready: true };
    }

    charge = async () => {
        // Return if no key
        if (!this.props.publishableKey) return;

        this.props.setActionRequestingAction('authorize_payment', true);
        try {
            await this.props.fetchCustomerTokenAction();
        } catch (e) {
            this.props.setActionRequestingAction('authorize_payment', false);
            // Something wrong happen, possibly display an error in the future
            return;
        }

        // If for whatever reason there is no customer token, throw
        if (!this.props.customerToken) throw new Error('no customer token');

        const client = getStrongholdPayClient(
            this.props.publishableKey.key,
            this.props.publishableKey.environment,
            this.props.apiVersion,
            this.props.integrationId,
        );
        client.tip(this.props.customerToken, {
            payLinkCode: this.props.payLinkCode,
            skipPayScreen: true,
            skipSuccessScreen: true,
            tip: {
                amount: this.props.tip.amount,
                beneficiaryName: this.props.tip.beneficiaryName,
                paymentSourceId: this.props.tip.paymentSourceId,
                chargeId: this.props.tip.chargeId,
                details: this.props.tip.details,
            },
            onSuccess: this.onSuccess,
            onExit: this.onExit,
            onError: this.onError,
        });
    };

    updatePaymentMethod = async () => {
        // Return if no key
        if (!this.props.publishableKey) return;

        this.setState({ ready: false });

        const client = getStrongholdPayClient(
            this.props.publishableKey.key,
            this.props.publishableKey.environment,
            this.props.apiVersion,
            this.props.integrationId,
        );
        client.updatePaymentSource(this.props.customerToken, {
            onSuccess: () => this.setState({ ready: true }),
            onExit: () => this.setState({ ready: true }),
            onError: (e) => {
                this.setState({ ready: true });
            },
            paymentSourceId: this.props.paymentSourceId,
        });
    };

    onSuccess: TipOnSuccess = (charge) => {
        this.props.setActionRequestingAction('authorize_payment', false);
        if (this.props.onSuccess) {
            this.props.onSuccess(charge);
        }
    };

    onError: OnError = async (e) => {
        if (e.code === ERROR_CODE.PAYMENT_SOURCE_LOGIN_REQUIRED) {
            this.setState({ ready: false });
            // TODO: see how to fix latency of event listener
            setTimeout(() => this.updatePaymentMethod(), 200);
            return;
        }
        this.props.setActionRequestingAction('authorize_payment', false);
    };

    onExit: OnExit = () => {
        this.props.setActionRequestingAction('authorize_payment', false);
    };

    render() {
        const {
            children = <React.Fragment>PAY</React.Fragment>,
            payLinkCode,
            isAuthorizingPayment,
            customerToken,
            paymentSourceId,
            onSuccess,
            onExit,
            fetchCustomerTokenAction,
            noSource,
            setActionRequestingAction,
            publishableKey,
            chargeId,
            tip,
            ...props
        } = this.props;
        const { ready } = this.state;

        let icon = <FontAwesomeIcon icon={faMoneyBill} fixedWidth />;

        const isLoading = isAuthorizingPayment;
        const isDisabled = isLoading || noSource || props.disabled || !publishableKey || !ready || !tip.amount;

        if (isLoading) {
            icon = <Spinner type="grow" color="white" size="sm" />;
        }

        return (
            <Button color="primary" {...props} onClick={this.charge} data-sh="charge-button" disabled={isDisabled}>
                {children}
                <span className="ml-2">{icon}</span>
            </Button>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, OwnProps, ApplicationState> = (state) => ({
    publishableKey: state.global.publishableApiKey,
    customerToken: state.authentication.customerToken || '',
    noSource: state.paymentSources.arr.length === 0,
    isAuthorizingPayment: selectors.global.isActionRequesting(state.global.actions, 'authorize_payment'),
    integrationId: state.attribution?.integrationId,
    apiVersion: state.attribution.apiVersion,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            fetchCustomerTokenAction,
            setActionRequestingAction,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(AddTipDropinButton);
