import { TokenModel } from '../store/types';
import { request } from './utils';

export async function getCustomerToken() {
    const res = await request<TokenModel>({
        method: 'GET',
        url: '/api/customers/token',
    });
    return res.result;
}
