import { merge } from 'lodash';
import * as Stronghold from '@stronghold/pay-dropin';
import * as Sentry from '@sentry/browser';
import { ConfigurationModel } from './apis';

export const FORMAT_DATE = 'YYYY-MM-DD';

// Open ID authentication
export const configuration: ConfigurationModel = {
    api_environment: 'live',
    environment: 'development',
    stronghold_pay_host: '',
    sentry_dsn: '',
    analytics: {
        environment: 'Development',
    },
    split: {
        api_key: '',
    },
    integration_id: '',
    api_version: 'v2',
};

export function setConfiguration(newConfiguration: ConfigurationModel) {
    merge(configuration, newConfiguration);

    if (configuration.sentry_dsn) {
        Sentry.init({
            dsn: configuration.sentry_dsn,
            environment: configuration.environment,
        });
    }
}

export function getStrongholdPayClient(
    publishableKey: string,
    environment: Stronghold.ENVIRONMENT,
    apiVersion: string,
    integrationId?: string,
) {
    return Stronghold.Pay({
        environment,
        host: configuration.stronghold_pay_host,
        publishableKey: publishableKey || '',
        integrationId: integrationId,
        apiVersion: apiVersion,
    });
}

// Router
export const ROUTE = {
    HOME: '/',
    LOGIN: '/login',
    LOGIN_CODE: '/login/code',
    SIGN_UP: '/signup',
    LOGOUT: '/logout',
    LINK_ACCOUNT: '/link-account',
    PROFILES: '/profiles',
    PAYMENT_SOURCES: '/payment-sources',
    SUPPORT: '/support',
    LINK: '/l/:code?',
};
