import { CustomerModel } from './auth';
import { MerchantModel, OrderItemModel, ApiKeyModel } from './types';
import { ENVIRONMENT, Charge, Tip } from '@stronghold/pay-dropin';
import { DeepPartial } from 'redux';
import { request } from './utils';

export type PayLinkType = 'checkout' | 'bank_link' | 'tipping';
export type PayLinkStatus = 'created' | 'used';

export interface PayLinkTipModel extends DeepPartial<Tip> {
    beneficiary_name: string;
    details: {
        display_message?: string;
        drawer_id?: string;
        terminal_id?: string;
    };
}

export interface PayLinkOrderModel {
    sub_amount: number;
    total_amount: number;
    tax_amount: number;
    convenience_fee: number;
    payment_processing_fee: number;
    items: OrderItemModel[];
    documents: PayLinkOrderDocumentModel[];
}

export interface PayLinkOrderDocumentModel {
    url: string;
    file_name: string;
    file_size: number;
}

export interface PayLinkModel {
    id: string;
    created_at: string;
    expires_at: string;
    type: PayLinkType;
    customer: CustomerModel;
    environment: ENVIRONMENT;
    status: PayLinkStatus;
    charge?: Charge;
    tip?: PayLinkTipModel | Tip;
    order?: PayLinkOrderModel;
    callbacks?: {
        success_url: string;
        exit_url: string;
    };
    // Internal pay link
    api_keys: { publishable: ApiKeyModel };
    merchant: MerchantModel;
    available_credit: number;
    integration_id: string;
    version: string;
}

export async function addCustomer(payLinkId: string, customerId: string) {
    const res = await request<PayLinkModel>({
        method: 'PUT',
        url: '/api/links/' + payLinkId + '/customer/' + customerId,
    });
    return res.result;
}
