import * as React from 'react';
import { Button, ButtonProps } from 'reactstrap';
import { connect, MapStateToPropsParam } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import Badge from 'reactstrap/lib/Badge';
import { ResponseError } from '../apis';
import { PaymentSource } from '@stronghold/pay-dropin';
import { ApplicationState } from '../store';
import selectors from '../store/selectors';
import { deactivatePaymentSourceAction, removePaymentSourceAction } from '../store/actions';
import { filter } from 'lodash';
import ConfirmationModal from './ConfirmationModal';

interface StateProps {
    errorMessage: ResponseError | null;
    paymentSources: PaymentSource[];
}

interface OwnProps extends ButtonProps {
    paymentSource: PaymentSource;
    customerId: string;
}

interface DispatchProps {
    deactivatePaymentSourceAction: typeof deactivatePaymentSourceAction;
    removePaymentSourceAction: typeof removePaymentSourceAction;
}

type Props = StateProps & OwnProps & DispatchProps;

export type PaymentSourceActionType = 'remove' | null;

interface State {
    ready: boolean;
    showModal: boolean;
    action: PaymentSourceActionType;
}

class PaymentSourceActions extends React.Component<Props, State> {
    public state: State = {
        ready: true,
        showModal: false,
        action: null,
    };

    onActionClick = async (action: PaymentSourceActionType) => {
        if (action === null) return;
        await this.setState({ action });
        this.toggleModal();
    };

    toggleModal = () => this.setState({ showModal: !this.state.showModal });

    onSubmit = async () => {
        const { paymentSource, paymentSources } = this.props;
        this.setState({ ready: false });
        try {
            if (this.state.action === null) return;

            const paymentSourcesToDelete = filter(
                paymentSources,
                (item) => item.unique_hash === paymentSource.unique_hash,
            );

            // remove payment sources
            for (const el of paymentSourcesToDelete) {
                await this.props.deactivatePaymentSourceAction(el.id);
            }

            this.toggleModal();
            setTimeout(() => {
                paymentSourcesToDelete.forEach((el) => this.props.removePaymentSourceAction(el.id));
            }, 500);
        } catch {
            this.setState({ ready: true });
            // Error
        }
        this.setState({ ready: true });
    };

    render() {
        const { paymentSource, errorMessage } = this.props;

        const { showModal } = this.state;
        const isLoading = !this.state.ready;
        const action = paymentSource.active ? 'remove' : null;

        return (
            <React.Fragment>
                <ConfirmationModal
                    amount={0}
                    isLoading={isLoading}
                    error={errorMessage}
                    type={action}
                    showModal={showModal}
                    toggle={this.toggleModal}
                    onSubmit={this.onSubmit}
                />
                {paymentSource.active ? (
                    <Button
                        className="text-capitalize"
                        color="link"
                        size="sm"
                        onClick={() => this.onActionClick(action)}
                    >
                        {action}
                    </Button>
                ) : (
                    <Badge color={'danger'}>Deactivated</Badge>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, OwnProps, ApplicationState> = (state) => ({
    paymentSources: state.paymentSources.arr,
    errorMessage: selectors.global.getResponseError(state.global.actions, 'update_payment_source'),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            deactivatePaymentSourceAction,
            removePaymentSourceAction,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(PaymentSourceActions);
