import * as React from 'react';

interface Props {
    isBlack: boolean;
}

export default function Powered(props: Props) {
    let imgSrc = '/images/';

    if (props.isBlack) {
        imgSrc += 'Powered-by-Stronghold.png';
    } else {
        imgSrc += 'Powered-by-Stronghold_1-p-500.png';
    }

    return (
        <div className="">
            <img src={`${imgSrc}`} className="img-responsive" alt="Stronghold Pay" height={30} />
        </div>
    );
}
