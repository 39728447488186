import { IS_AUTHENTICATED, SET_TOKEN, UPDATE_CUSTOMER } from '../constants';
import { AppThunkAction } from '../index';
import {
    ApplicationAction,
    fetchChargesAction,
    resetActionStatusAction,
    setActionRequestingAction,
    setActionResponseErrorAction,
    setCreditValueAction,
    setErrorMessageAction,
    setPayLinkAction,
    setPaymentSourcesAction,
} from './index';
import * as api from '../../apis';
import { AuthenticationMethod, CustomerModel, LoginResponseModel, ResponseError, ResponseErrorCode } from '../../apis';
import { DeepPartial } from 'redux';
import { fetchMerchantTreatmentsAction } from './splitio';
import { SPLIT_FLAG } from '../types';
import selectors from '../selectors';
import { setApiVersionAction, setIntegrationIdAction } from './attribution';

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface IsAuthenticatedAction {
    type: IS_AUTHENTICATED;
    payload: {
        isAuthenticated: boolean;
    };
}

export function isAuthenticatedAction(isAuthenticated: boolean): IsAuthenticatedAction {
    return {
        type: IS_AUTHENTICATED,
        payload: { isAuthenticated },
    };
}

interface SetTokenAction {
    type: SET_TOKEN;
    payload: {
        token: string | null;
    };
}

export function setTokenAction(token: string | null = null, save = true): SetTokenAction {
    if (save) {
        if (!!token) {
            localStorage.setItem('token', token);
        } else {
            localStorage.removeItem('token');
        }
    }

    return {
        type: SET_TOKEN,
        payload: { token },
    };
}

interface UpdateCustomerAction {
    type: UPDATE_CUSTOMER;
    payload: {
        customer: DeepPartial<CustomerModel>;
    };
}

export function updateCustomerAction(customer: DeepPartial<CustomerModel>): UpdateCustomerAction {
    return {
        type: UPDATE_CUSTOMER,
        payload: { customer },
    };
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type AuthenticationAction = IsAuthenticatedAction | SetTokenAction | UpdateCustomerAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

const waitForSplits = () => {
    return new Promise((resolve) => {
        setTimeout(() => resolve(), 200);
    });
};

export function loginAction(
    authentication: AuthenticationMethod,
    username: string,
    merchant_short_name?: string,
): AppThunkAction<ApplicationAction> {
    return async (dispatch, getState) => {
        if (getState().authentication.isAuthenticated) {
            return;
        }

        dispatch(resetActionStatusAction('login'));
        dispatch(setActionRequestingAction('login', true));

        let result: LoginResponseModel;
        let showSimpleWorkflow;
        try {
            // fetch merchant as we need an identifier to send to split to retrieve feature flags
            const merchant = await api.fetchMerchant(username);
            if (merchant) {
                dispatch(fetchMerchantTreatmentsAction(merchant) as unknown as ApplicationAction);
            }
            // allow split treatments to load
            await waitForSplits();
            const loadedSplits = getState().splitio.treatments;
            showSimpleWorkflow = selectors.global.getSplitFlagValue(SPLIT_FLAG.SIMPLE_CHECKOUT, loadedSplits);
        } catch (e) {
            // Login action should continue despite failure to retrieve SIMPLE_CHECKOUT split settings
        }

        try {
            result = await api.login({
                authentication: {
                    method: authentication,
                },
                username,
                with_order_items: !showSimpleWorkflow,
                merchant_short_name,
            });
        } catch (e) {
            if (e instanceof ResponseError) {
                e.property = e.property && e.property.replace('customer.', '');
                dispatch(setActionResponseErrorAction('login', e));

                if (authentication === 'link') {
                    // Handle link availability errors
                    if (e.code === ResponseErrorCode.PayLinkExpired) {
                        dispatch(setErrorMessageAction(ResponseErrorCode.PayLinkExpired));
                    } else if (e.code === ResponseErrorCode.PayLinkCanceled) {
                        dispatch(setErrorMessageAction(ResponseErrorCode.PayLinkCanceled));
                    } else if (e.code === ResponseErrorCode.MerchantSoftwareError) {
                        dispatch(setErrorMessageAction(ResponseErrorCode.MerchantSoftwareError));
                    } else if (e.code === ResponseErrorCode.PaylinkChargeAmountModified) {
                        dispatch(setErrorMessageAction(ResponseErrorCode.PaylinkChargeAmountModified));
                    } else if (e.code === ResponseErrorCode.NotFound) {
                        dispatch(setErrorMessageAction(ResponseErrorCode.NotFound));
                    } else if (e.code === ResponseErrorCode.InvalidApiKey) {
                        dispatch(setErrorMessageAction(ResponseErrorCode.InvalidApiKey));
                    } else if (e.code === ResponseErrorCode.LiveNotApproved) {
                        dispatch(setErrorMessageAction(ResponseErrorCode.LiveNotApproved));
                    } else if (e.code === ResponseErrorCode.InvalidChargeStatus) {
                        dispatch(setErrorMessageAction(ResponseErrorCode.InvalidChargeStatus));
                    } else if (e.code === ResponseErrorCode.PayLinkMissingCharge) {
                        dispatch(setErrorMessageAction(ResponseErrorCode.PayLinkMissingCharge));
                    } else if (e.code === ResponseErrorCode.PayLinkMissingOrder) {
                        dispatch(setErrorMessageAction(ResponseErrorCode.PayLinkMissingOrder));
                    } else {
                        dispatch(setErrorMessageAction(ResponseErrorCode.ServerError));
                    }
                }
            }
            dispatch(setActionRequestingAction('login', false));
            throw e;
        }
        let integrationId;
        if (result.link) {
            integrationId = result.link.integration_id;
            dispatch(setPayLinkAction(result.link));
            dispatch(setApiVersionAction('v' + result.link.version));
            if (!result.link.customer) {
                dispatch(setActionRequestingAction('login', false));
                throw ResponseErrorCode.CustomerRequired;
            } else {
                // Is authenticated directly if login using link only
                dispatch(isAuthenticatedAction(true));
            }
        } else {
            integrationId = getState().global.configuration.integration_id;
            dispatch(setApiVersionAction(getState().global.configuration.api_version));
        }

        dispatch(setTokenAction(result.token));
        dispatch(updateCustomerAction(result.customer));
        dispatch(setPaymentSourcesAction(result.customer.payment_sources.filter((s) => s.active) || []));
        dispatch(setCreditValueAction(result.link?.available_credit || 0));
        dispatch(setIntegrationIdAction(integrationId));

        // If authentication not link, we fetch the charges as well
        if (authentication !== 'link') {
            await fetchChargesAction()(dispatch, getState);
        }

        dispatch(setActionRequestingAction('login', false));
    };
}

export function resendTokenAction(
    mobile: string,
    email: string,
    authMethod: AuthenticationMethod,
): AppThunkAction<ApplicationAction> {
    return async (dispatch) => {
        dispatch(resetActionStatusAction('verify'));
        dispatch(setActionRequestingAction('verify', true));
        dispatch(setTokenAction(null));
        let error: Error | null = null;

        try {
            const result = await api.resendVerificationToken({
                authentication: { method: authMethod },
                customer: { individual: { mobile, email } },
            });
            dispatch(setTokenAction(result.token));
        } catch (e) {
            const responseError = e as ResponseError;
            responseError.property = responseError.property && responseError.property.replace('customer.', '');
            dispatch(setActionResponseErrorAction('verify', responseError));
            error = responseError;
        } finally {
            dispatch(setActionRequestingAction('verify', false));
        }
        if (error) {
            throw error;
        }
    };
}

export function signupAction(
    first_name: string,
    last_name: string,
    date_of_birth: string,
    mobile: string,
    email: string,
    method: AuthenticationMethod,
    merchant_short_name?: string,
): AppThunkAction<ApplicationAction> {
    return async (dispatch) => {
        dispatch(resetActionStatusAction('signup'));
        dispatch(setActionRequestingAction('signup', true));

        let error: Error | null = null;

        try {
            const result = await api.signup({
                authentication: { method },
                customer: {
                    individual: { first_name, last_name, date_of_birth, mobile, email },
                },
                merchant_short_name,
            });
            dispatch(updateCustomerAction(result.customer));
            dispatch(setTokenAction(result.token));
        } catch (e) {
            if (e instanceof ResponseError) {
                e.property = e.property && e.property.replace('customer.', '');
                dispatch(setActionResponseErrorAction('signup', e));
            }
            error = e;
        } finally {
            dispatch(setActionRequestingAction('signup', false));
        }
        if (error) {
            throw error;
        }
    };
}

export function verifyAction(code: string): AppThunkAction<ApplicationAction> {
    return async (dispatch, getState) => {
        dispatch(resetActionStatusAction('verify'));
        dispatch(setActionRequestingAction('verify', true));

        const token = getState().authentication.token || '';

        let error: Error | null = null;

        try {
            const result = await api.verify({ code, token });
            dispatch(setTokenAction(result.token));
        } catch (e) {
            if (e instanceof ResponseError) {
                dispatch(setActionResponseErrorAction('verify', e));
            }
            error = e;
        } finally {
            dispatch(setActionRequestingAction('verify', false));
        }

        if (error) {
            throw error;
        }
    };
}

export function fetchProfileAction(): AppThunkAction<ApplicationAction> {
    return async (dispatch, getState) => {
        // If authenticated, return directly
        if (getState().authentication.isAuthenticated) {
            return;
        }

        // Check existing token
        const token = localStorage.getItem('token');
        // If no token present, we dont try to authenticate
        if (!token) {
            return;
        }

        dispatch(setActionRequestingAction('authenticate', true));

        let error: Error | null = null;
        try {
            const result = await api.fetchProfile();
            dispatch(isAuthenticatedAction(true));
            dispatch(updateCustomerAction(result.customer));
            dispatch(setPaymentSourcesAction(result.customer.payment_sources.filter((s) => s.active) || []));

            // We fetch the charges as well
            await fetchChargesAction()(dispatch, getState);
        } catch (e) {
            error = e;
            localStorage.removeItem('token');
        } finally {
            dispatch(setActionRequestingAction('authenticate', false));
        }
        if (error) {
            throw error;
        }
    };
}

export function logoutAction(): AppThunkAction<ApplicationAction> {
    return async (dispatch) => {
        dispatch(isAuthenticatedAction(false));
        dispatch(setTokenAction(null));
    };
}
