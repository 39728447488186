import * as React from 'react';
import { connect, MapStateToPropsParam } from 'react-redux';
import { ApplicationState } from '../../store';
import { ChargeModel } from '../../apis';
import { isActionRequesting } from '../../store/selectors/global';
import Table from 'reactstrap/lib/Table';
import selectors from '../../store/selectors';
import Pagination from '../../components/Pagination';
import ChargeStatusBadge from './ChargeStatusBadge';
import TableBody from '../TableBody';

interface StateProps {
    charges: ChargeModel[];
    chargesTotal: number;
    chargesSkipped: number;
    chargesLimit: number;
    isLoading: boolean;
}

interface OwnerProps {
    customerId?: string;
    isLoading?: boolean;
    onGoTo: (page: number, skip: number) => void;
    noRowsComponent?: () => React.ReactNode;
}

type Props = StateProps & OwnerProps;

class ChargesTable extends React.Component<Props> {
    hasNoRows = () => this.props.charges.length === 0 && !this.props.isLoading;

    renderNoRows = () => {
        if (!this.hasNoRows()) return null;
        return (
            <div className="text-center p-5">
                {this.props.noRowsComponent ? this.props.noRowsComponent() : 'There are no transactions to display'}
            </div>
        );
    };

    render() {
        return (
            <React.Fragment>
                <Table hover responsive className={['table-sm', this.hasNoRows() ? 'mb-0' : ''].join(' ')}>
                    <thead>
                        <tr>
                            <th style={{ minWidth: 140 }}>Date</th>
                            <th>Status</th>
                            <th>Amount</th>
                        </tr>
                    </thead>
                    <TableBody isLoading={this.props.isLoading} rows={this.props.chargesLimit} columns={4}>
                        {this.props.charges.map((charge, index) => (
                            <tr key={index}>
                                <td>{selectors.format.formatDateTimeMinutes(charge.created_at)}</td>
                                <td>
                                    <ChargeStatusBadge status={charge.status} />
                                </td>
                                <td>{selectors.format.formatAmount(charge.amount)}</td>
                            </tr>
                        ))}
                    </TableBody>
                </Table>
                {this.renderNoRows()}
                <Pagination
                    total={this.props.chargesTotal}
                    limit={this.props.chargesLimit}
                    skipped={this.props.chargesSkipped}
                    onClick={(skip) => this.props.onGoTo(skip / this.props.chargesLimit + 1, skip)}
                    disabled={this.props.isLoading}
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, OwnerProps, ApplicationState> = (
    state,
    { isLoading = false },
) => ({
    charges: state.charge.list.items,
    chargesSkipped: state.charge.list.skipped,
    chargesTotal: state.charge.list.total,
    chargesLimit: state.charge.list.limit,
    isLoading: isActionRequesting(state.global.actions, 'fetch_charges') || isLoading,
});

export default connect(mapStateToProps)(ChargesTable);
