export enum ResponseErrorType {
    Unknown = 'unknown',
    ValidationError = 'validation_error',
}

export enum ResponseErrorCode {
    // =========================
    // Portal
    Unknown = 'unknown',
    InvalidCredentials = 'invalid_credentials',

    // =========================
    // Pay API errors

    // Api error
    ServerError = 'server_error',
    ConnectionError = 'connection_error',

    // Merchant software error
    MerchantSoftwareError = 'merchant_software_error',

    // Authentication error
    InvalidApiKey = 'invalid_api_key',
    ForbiddenResource = 'forbidden_resource',
    InvalidAccessToken = 'invalid_access_token',
    LiveNotApproved = 'live_not_approved',

    // Invalid request
    NotFound = 'not_found',
    InvalidId = 'invalid_id',
    TicketNotFound = 'ticket_not_found',
    DispensaryNotFound = 'dispensary_not_found',
    SandboxOnly = 'sandbox_only',

    // Object error
    InvalidOperation = 'invalid_operation',
    PaymentSourceAlreadyExists = 'payment_source_already_exists',
    PaymentSourceLoginRequired = 'payment_source_login_required',
    PaymentSourceUnavailable = 'payment_source_unavailable',
    InsufficientBalance = 'insufficient_balance',
    CustomerBlocked = 'customer_blocked',
    PayLinkCanceled = 'pay_link_canceled',
    PayLinkExpired = 'pay_link_expired',
    PaylinkChargeAmountModified = 'paylink_charge_amount_modified',
    PayLinkChargeAlreadyPaid = 'pay_link_charge_already_paid',
    CustomerRequired = 'customer_required',
    InvalidChargeStatus = 'invalid_charge_status',
    PayLinkMissingCharge = 'paylink_missing_charge',
    PayLinkMissingOrder = 'paylink_missing_order',

    // Validation error
    MissingField = 'missing_field',
    InvalidField = 'invalid_field',
    ValueTaken = 'value_taken',
}

export interface PortalResponseError {
    type: string;
    code: string;
    property: string | null;
}

export class ResponseError extends Error implements PortalResponseError {
    type: ResponseErrorType;
    code: ResponseErrorCode;
    property: string | null;

    public constructor(type: ResponseErrorType, code: ResponseErrorCode, property: string | null = null) {
        super(`${type}: ${code}, ${property}`);
        this.name = 'ResponseError';
        this.type = type;
        this.code = code;
        this.property = property;
    }
}

export interface PortalResponse<T> {
    result: T;
}

export interface ListSegment<T> {
    total: number;
    limit: number;
    skipped: number;
    items: T[];
}

export interface PortalResponseFailed {
    error: PortalResponseError | null;
}
