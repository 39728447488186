import {
    AuthenticationState,
    GlobalState,
    PayLinkState,
    ChargeState,
    PaymentSourcesState,
    SplitIoState,
    CreditState,
} from './types';
import { authenticationReducer, globalReducer, payLinkReducer, chargeReducer, paymentSourcesReducer } from './reducers';
import { splitReducer } from '@splitsoftware/splitio-redux';
import { creditReducer } from './reducers';
import { AttributionState } from './types/attribution';
import { attributionReducer } from './reducers/attribution';

// The top-level state object
export interface ApplicationState {
    authentication: AuthenticationState;
    payLink: PayLinkState;
    paymentSources: PaymentSourcesState;
    global: GlobalState;
    charge: ChargeState;
    splitio: SplitIoState;
    credits: CreditState;
    attribution: AttributionState;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    authentication: authenticationReducer,
    payLink: payLinkReducer,
    paymentSources: paymentSourcesReducer,
    global: globalReducer,
    charge: chargeReducer,
    splitio: splitReducer,
    credits: creditReducer,
    attribution: attributionReducer,
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
