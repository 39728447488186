import { SET_PAY_LINK, UPDATE_PAY_LINK } from '../constants';
import { PayLinkModel } from '../../apis';
import { DeepPartial } from 'redux';
import { AppThunkAction } from '../index';
import { ApplicationAction, setActionRequestingAction } from './index';
import * as api from '../../apis';

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

export interface SetPayLinkAction {
    type: SET_PAY_LINK;
    payload: {
        link: PayLinkModel;
    };
}

export const setPayLinkAction = (link: PayLinkModel): SetPayLinkAction => ({
    type: SET_PAY_LINK,
    payload: {
        link,
    },
});

export interface UpdatePayLinkAction {
    type: typeof UPDATE_PAY_LINK;
    payload: { payLink: DeepPartial<PayLinkModel> };
}

export const updatePayLinkAction = (payLink: DeepPartial<PayLinkModel>): UpdatePayLinkAction => ({
    type: UPDATE_PAY_LINK,
    payload: { payLink },
});

export function setPayLinkCustomerAction(payLinkId: string, customerId: string): AppThunkAction<ApplicationAction> {
    return async (dispatch) => {
        await dispatch(setActionRequestingAction('set_pay_link_customer', true));
        try {
            await api.addCustomer(payLinkId, customerId);
            await dispatch(setActionRequestingAction('set_pay_link_customer', false));
        } catch (e) {
            dispatch(setActionRequestingAction('set_pay_link_customer', false));
            throw e;
        }
    };
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type PayLinkAction = SetPayLinkAction | UpdatePayLinkAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
