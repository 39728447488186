import { ApplicationState } from '../index';

export enum CardType {
    Visa = 'visa',
    MasterCard = 'mastercard',
    AmericanExpress = 'amex',
    DinersClub = 'dinersclub',
}

export function getPaymentSourceSelected(state: ApplicationState) {
    return state.paymentSources.arr.find((el) => el.id === state.paymentSources.paymentSourceSelected) || null;
}

export function getTypeOfCard(provider: string): CardType | undefined {
    switch (provider.toLowerCase()) {
        case 'visa':
            return CardType.Visa;
        case 'mastercard':
            return CardType.MasterCard;
        case 'dinersclub':
            return CardType.DinersClub;
        case 'amex':
            return CardType.AmericanExpress;
    }
}
