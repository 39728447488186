import * as React from 'react';
import { CHARGE_STATUS } from '../../apis';
import Badge from 'reactstrap/lib/Badge';

export interface Props {
    status: CHARGE_STATUS;
}

export default function ChargeStatusBadge(props: Props) {
    const { status } = props;
    let textColor = 'primary';
    let text = status.replace('_', ' ');

    if (status === 'captured') {
        textColor = 'success';
        text = 'Completed';
    } else if (status === 'authorized') {
        textColor = 'warning';
        text = 'Pending';
    } else if (status === 'attempting_collection') {
        textColor = 'warning';
        text = 'Retrying';
    } else if (status === 'disputed') {
        textColor = 'warning';
        text = 'Disputed';
    } else if (status === 'refund_pending') {
        textColor = 'warning';
        text = 'Processing refund';
    } else if (status === 'refunded') {
        textColor = 'primary';
        text = 'Refunded';
    } else if (status === 'capture_failed') {
        textColor = 'danger';
        text = 'Insufficient funds';
    } else if (status === 'stopped_collection') {
        textColor = 'danger';
        text = 'Stopped Collection';
    } else if (status === 'canceled') {
        textColor = 'danger';
        text = 'Canceled';
    }

    return (
        <Badge pill color={textColor}>
            {text}
        </Badge>
    );
}
