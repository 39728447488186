import * as React from 'react';
import MESSAGE from '../message';

export default function PaymentSourceRequiredWarning() {
    return (
        <div
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}
            className="mt-3"
        >
            <div>{MESSAGE.PAYMENT_METHOD_NONE}</div>
        </div>
    );
}
