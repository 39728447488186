import { request } from './utils';
import { DeepPartial } from 'redux';
import { PayLinkModel } from './payLink';
import { PaymentSource } from '@stronghold/pay-dropin';
import { MerchantModel } from './types';

export type AuthenticationMethod = 'sms' | 'email' | 'link';

export interface AuthenticationModel {
    method: AuthenticationMethod;
}

export interface CustomerIndividualDetails {
    email: string | null;
    mobile: string | null;
    first_name: string | null;
    last_name: string | null;
    date_of_birth: string | null;
}

export interface CustomerModel {
    id: string | null;
    country: string | null;
    state: string | null;
    individual: CustomerIndividualDetails;
    payment_sources: PaymentSource[];
}

export interface SignupRequestModel {
    authentication: AuthenticationModel;
    customer: DeepPartial<CustomerModel>;
    merchant_short_name?: string;
}

export interface LoginRequestModel {
    authentication: AuthenticationModel;
    username: string;
    with_order_items?: boolean;
    merchant_short_name?: string;
}

export interface ProfileResponseModel {
    customer: CustomerModel;
}

export interface VerifyRequestModel {
    token: string;
    code: string;
}

export interface LoginResponseModel {
    authentication: AuthenticationModel;
    token: string;
    customer: CustomerModel;
    link?: PayLinkModel;
}

export interface SignupResponseModel {
    authentication: AuthenticationModel;
    token: string;
    customer: CustomerModel;
}

export async function resendVerificationToken(data: SignupRequestModel) {
    const res = await request<SignupResponseModel>({
        method: 'POST',
        url: '/api/auth/resend',
        data,
    });
    return res.result;
}

export async function signup(data: SignupRequestModel) {
    const res = await request<SignupResponseModel>({
        method: 'POST',
        url: '/api/auth/signup',
        data,
    });
    return res.result;
}

export async function verify(data: VerifyRequestModel) {
    const res = await request<VerifyRequestModel>({
        method: 'POST',
        url: '/api/auth/verify',
        data,
    });
    return res.result;
}

export async function login(data: LoginRequestModel) {
    const res = await request<LoginResponseModel>({
        method: 'POST',
        url: '/api/auth/login',
        data,
    });

    return res.result;
}

export async function fetchMerchant(code: string) {
    const res = await request<MerchantModel>({
        method: 'GET',
        url: `/api/auth/merchant/${code}`,
    });

    return res.result;
}

export async function fetchProfile() {
    const res = await request<ProfileResponseModel>({
        method: 'GET',
        url: '/api/auth/profile',
    });
    return res.result;
}
