import 'react';

import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect, MapStateToPropsParam } from 'react-redux';
import { ApplicationState } from '../../store';
import Layout from '../../components/Layout';
import { bindActionCreators, Dispatch } from 'redux';
import { PayLinkModel, ResponseErrorCode } from '../../apis';
import { isAuthenticatedAction, loginAction } from '../../store/actions';
import Spinner from 'reactstrap/lib/Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import selectors from '../../store/selectors';
import CheckoutPage from './CheckoutPage';
import BankLinkPage from './BankLinkPage';
import TipPage from './TipPage';
import SignUpPage from '../SignUpPage';
import LoginPage from '../LoginPage';

interface StateProps {
    link: PayLinkModel | null;
    exitUrlCallback: string | null;
}

type OwnProps = RouteComponentProps<{ code?: string }>;

interface DispatchProps {
    isAuthenticatedAction: typeof isAuthenticatedAction;
    loginAction: typeof loginAction;
}

type Props = OwnProps & StateProps & DispatchProps;

type Page = 'signup' | 'login';

interface State {
    loaded: boolean;
    page?: Page;
}

class PayLinkPage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            loaded: false,
        };
    }

    async componentDidMount() {
        await this.props.isAuthenticatedAction(false);
        const code = this.props.match.params.code || '';

        try {
            await this.props.loginAction('link', code);
            this.setState({ loaded: true });
        } catch (e) {
            if (e === ResponseErrorCode.CustomerRequired) {
                this.setState({ page: 'signup' });
                this.setState({ loaded: true });
            }
        }
    }

    navigateToLogin = () => {
        this.setState({ page: 'login' });
    };

    navigateToSignup = () => {
        this.setState({ page: 'signup' });
    };

    render() {
        const { link, exitUrlCallback, history, location, match } = this.props;

        if (this.state.loaded && this.state.page) {
            if (this.state.page === 'login') {
                return (
                    <Layout navbar={false} footer={false} centered>
                        <LoginPage
                            history={history}
                            match={match}
                            location={location}
                            navToSignup={this.navigateToSignup}
                        />
                    </Layout>
                );
            } else {
                return (
                    <Layout navbar={false} footer={false} centered>
                        <SignUpPage
                            history={history}
                            match={match}
                            location={location}
                            navToLogin={this.navigateToLogin}
                        />
                    </Layout>
                );
            }
        }

        if (!this.state.loaded || !link) {
            return (
                <Layout navbar={false} centered>
                    <Spinner style={{ width: '3rem', height: '3rem', borderWidth: '1px' }} color="secondary" />
                </Layout>
            );
        }

        let payLinkPage = <></>;
        let exit = <React.Fragment />;
        let className = '';

        if (exitUrlCallback && link.status !== 'used') {
            exit = (
                <div className="d-inline-block position-relative mt-3" data-sh="exit">
                    <FontAwesomeIcon icon={faArrowLeft} />
                    <a className="ml-3 stretched-link text-dark" href={exitUrlCallback}>
                        Back
                    </a>
                </div>
            );
        }

        if (link.type === 'bank_link') {
            className = 'mw-sm';
            payLinkPage = <BankLinkPage link={link} />;
        } else if (link.type === 'tipping') {
            payLinkPage = <TipPage link={link} />;
        } else if (link.type === 'checkout') {
            payLinkPage = <CheckoutPage link={link} />;
        } else {
            throw new Error(`PayLink of type ${link.type} not implemented`);
        }

        return (
            <Layout navbar={false} className={className}>
                {exit}
                {payLinkPage}
            </Layout>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, OwnProps, ApplicationState> = (state) => {
    const link = state.payLink.data ? state.payLink.data : null;

    return {
        link,
        exitUrlCallback: selectors.payLink.getExitUrlCallback(state),
    };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            isAuthenticatedAction,
            loginAction,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PayLinkPage));
