import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect, MapStateToPropsParam } from 'react-redux';
import { ApplicationState } from '../store';
import { bindActionCreators, Dispatch } from 'redux';
import { logoutAction } from '../store/actions';
import { ROUTE } from '../config';

type OwnProps = RouteComponentProps;

interface DispatchProps {
    logoutAction: typeof logoutAction;
}

type Props = DispatchProps & OwnProps;

class LogoutPage extends React.Component<Props> {
    componentDidMount(): void {
        this.props.logoutAction();
        this.props.history.push(ROUTE.LOGIN);
    }

    render() {
        return null;
    }
}

const mapStateToProps: MapStateToPropsParam<void, OwnProps, ApplicationState> = () => ({});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            logoutAction,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LogoutPage));
