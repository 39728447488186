import * as global from './global';
import * as number from './number';
import * as format from './format';
import * as authentication from './authentication';
import * as customizations from './customizations';
import * as payLink from './payLink';
import * as paymentSources from './paymentSources';

const selectors = {
    global,
    number,
    authentication,
    customizations,
    format,
    payLink,
    paymentSources,
};

export default selectors;
