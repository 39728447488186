import {
    ADD_PAYMENT_SOURCE,
    REMOVE_PAYMENT_SOURCE,
    SELECT_PAYMENT_SOURCE,
    SET_PAYMENT_SOURCES,
    UPDATE_PAYMENT_SOURCE,
    IS_NEW_PAYMENT_SOURCE_LINKED,
} from '../constants';
import { Reducer } from 'redux';
import { ApplicationAction } from '../actions';
import { PaymentSourcesState } from '../types';
import { filter, unionBy } from 'lodash';

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: PaymentSourcesState = {
    arr: [],
    paymentSourceSelected: null,
    isNewPaymentSourceLinked: false,
};

export const paymentSourcesReducer: Reducer<PaymentSourcesState, ApplicationAction> = (
    state = unloadedState,
    action,
): PaymentSourcesState => {
    switch (action.type) {
        case SET_PAYMENT_SOURCES:
            return {
                ...state,
                arr: action.payload.arr,
                paymentSourceSelected: action.payload.arr.length === 1 ? action.payload.arr[0].id : null,
            };
        case ADD_PAYMENT_SOURCE:
            return {
                ...state,
                arr: [...state.arr, action.payload.data],
            };
        case UPDATE_PAYMENT_SOURCE:
            return {
                ...state,
                arr: unionBy([action.payload.data], state.arr, (el) => el.id),
                paymentSourceSelected:
                    action.payload.data.id === state.paymentSourceSelected && !action.payload.data.active
                        ? null
                        : state.paymentSourceSelected,
            };
        case REMOVE_PAYMENT_SOURCE:
            return {
                ...state,
                arr: filter(state.arr, (item) => item.id !== action.payload.paymentSourceId),
                paymentSourceSelected:
                    state.paymentSourceSelected === action.payload.paymentSourceId ? null : state.paymentSourceSelected,
            };
        case SELECT_PAYMENT_SOURCE:
            return {
                ...state,
                paymentSourceSelected: action.payload.paymentSourceId,
            };
        case IS_NEW_PAYMENT_SOURCE_LINKED:
            return {
                ...state,
                isNewPaymentSourceLinked: action.payload.isNewPaymentSourceLinked,
            };
        default:
            return state;
    }
};
