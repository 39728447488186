import 'react';
import * as React from 'react';
import Card from 'reactstrap/lib/Card';
import CardBody from 'reactstrap/lib/CardBody';
import Form from 'reactstrap/lib/Form';
import { RouteComponentProps, withRouter } from 'react-router';
import AddPaymentSourceButton from '../components/dropin/AddPaymentSourceButton';
import { ROUTE } from '../config';
import Layout from '../components/Layout';
import MerchantImg from '../components/MerchantImg';
import { connect, MapStateToPropsParam } from 'react-redux';
import { ApplicationState } from '../store';
import { isNewPaymentSourceLinkedAction } from '../store/actions';
import { bindActionCreators, Dispatch } from 'redux';

interface StateProps {
    merchantDisplayName: string;
}

interface DispatchProps {
    isNewPaymentSourceLinkedAction: typeof isNewPaymentSourceLinkedAction;
}

type Props = StateProps & DispatchProps & RouteComponentProps;

class LinkAccountPage extends React.PureComponent<Props> {
    onSuccess = () => {
        this.props.isNewPaymentSourceLinkedAction(true);
        this.props.history.push(ROUTE.HOME);
    };

    public render() {
        return (
            <Layout navbar={false} centered>
                <Card className="shadow-lg text-center" style={{ maxWidth: 500 }}>
                    <CardBody className="p-md-5">
                        <div className="h4">
                            Link account for <strong>{this.props.merchantDisplayName}</strong>
                        </div>
                        <MerchantImg />
                        <div className="lead mb-4">
                            <p>
                                In order to make payments you will need to securely verify and link your bank details.
                            </p>
                            <p>Your credentials will never be stored or shared.</p>
                        </div>
                        <Form>
                            <AddPaymentSourceButton onSuccess={() => this.onSuccess()} />
                        </Form>
                    </CardBody>
                </Card>
            </Layout>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, void, ApplicationState> = (state) => ({
    merchantDisplayName: state.global.merchant.display_name,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            isNewPaymentSourceLinkedAction,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LinkAccountPage));
