import * as api from '../../apis';
import { SET_CUSTOMER_TOKEN } from '../constants';
import { setActionRequestingAction } from './global';
import { AppThunkAction } from '../index';
import { ApplicationAction } from './index';

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

export interface SetCustomerTokenAction {
    type: SET_CUSTOMER_TOKEN;
    payload: {
        token: string | null;
    };
}

export const setCustomerTokenAction = (token: string | null): SetCustomerTokenAction => ({
    type: SET_CUSTOMER_TOKEN,
    payload: {
        token,
    },
});

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type CustomerAction = SetCustomerTokenAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export function fetchCustomerTokenAction(): AppThunkAction<ApplicationAction> {
    return async (dispatch) => {
        await dispatch(setActionRequestingAction('fetch_customer_token', true));
        try {
            const result = await api.getCustomerToken();
            await dispatch(setCustomerTokenAction(result.token));
            await dispatch(setActionRequestingAction('fetch_customer_token', false));
        } catch (e) {
            dispatch(setActionRequestingAction('fetch_customer_token', false));
            throw e;
        }
    };
}
