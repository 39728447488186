import * as React from 'react';
import Media from 'reactstrap/lib/Media';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-solid-svg-icons/faImage';
import selectors from '../../store/selectors';
import { OrderItemModel } from '../../apis';

export interface Props {
    item: OrderItemModel;
}

export default function OrderItem(props: Props) {
    const { total_amount, price, description, image_url, name, quantity } = props.item;
    return (
        <Media className={`align-items-stretch`} data-sh="order-item">
            {image_url ? (
                <img className="mr-3" src={image_url} alt={name} width={52} height={52} />
            ) : (
                <FontAwesomeIcon icon={faImage} style={{ fontSize: 52 }} color={'lightgray'} className="mr-3" />
            )}
            <div className="media-body d-flex justify-content-between">
                <div className="d-flex flex-column">
                    <div data-sh="name">{name}</div>
                    {description ? (
                        <small data-sh="description" className="text-break text-muted">
                            {description}
                        </small>
                    ) : null}
                    {!!price && price !== total_amount ? (
                        <small data-sh="price">{selectors.number.amountToString(price)} per item</small>
                    ) : null}
                    <small data-sh="quantity">x {quantity}</small>
                </div>
                <div className="d-flex justify-content-between">
                    <div data-sh="amount" className="h5 mb-0 font-weight-bolder">
                        {selectors.number.amountToString(total_amount)}
                    </div>
                </div>
            </div>
        </Media>
    );
}
