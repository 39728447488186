import * as React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { connect, MapStateToPropsParam } from 'react-redux';
import { ApplicationState } from '../store';
import Container from 'reactstrap/lib/Container';
import Dropdown from 'reactstrap/lib/Dropdown';
import DropdownToggle from 'reactstrap/lib/DropdownToggle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons/faUserCircle';
import DropdownMenu from 'reactstrap/lib/DropdownMenu';
import DropdownItem from 'reactstrap/lib/DropdownItem';
import { ROUTE } from '../config';
import selectors from '../store/selectors';

interface StateProps {
    isAuthenticated: boolean;
    name: string;
    merchantDisplayName: string;
}

type OwnProps = RouteComponentProps;

type Props = StateProps & OwnProps;

type State = {
    isOpen: boolean;
};

class NavMenu extends React.PureComponent<Props, State> {
    public state: State = {
        isOpen: false,
    };

    toggle = () => {
        this.setState({ isOpen: !this.state.isOpen });
    };

    public render() {
        return (
            <Container className="py-3 d-flex justify-content-between">
                <div className="h4 m-0">
                    <strong>{this.props.merchantDisplayName}</strong> Customer Payments
                </div>
                <Dropdown isOpen={this.state.isOpen} toggle={this.toggle} direction="down">
                    <DropdownToggle
                        size="sm"
                        color="primary"
                        outline
                        caret={false}
                        className="d-flex align-items-center rounded-circle p-1"
                    >
                        <FontAwesomeIcon icon={faUserCircle} size="2x" />
                    </DropdownToggle>
                    <DropdownMenu right>
                        <DropdownItem disabled style={{ minWidth: 230 }}>
                            <div className="h5">{this.props.name}</div>
                        </DropdownItem>
                        <DropdownItem divider />
                        <Link className="dropdown-item" to={ROUTE.LOGOUT}>
                            <div>Sign out</div>
                        </Link>
                    </DropdownMenu>
                </Dropdown>
            </Container>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, OwnProps, ApplicationState> = (state) => ({
    isAuthenticated: state.authentication.isAuthenticated,
    name: selectors.authentication.getFullName(state.authentication),
    merchantDisplayName: state.global.merchant.display_name,
});

export default withRouter(connect(mapStateToProps)(NavMenu));
