import * as React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap';
import { PaymentSourceActionType } from './PaymentSourceActions';
import { ResponseError } from '../apis';
import Alert from 'reactstrap/lib/Alert';

type Props = {
    showModal: boolean;
    amount: number;
    toggle: () => void;
    onSubmit: () => void;
    type: PaymentSourceActionType;
    error: ResponseError | null;
    isLoading: boolean;
};

export default class ConfirmationModal extends React.Component<Props> {
    formatErrorMessage = (errorCode: string) => {
        switch (errorCode) {
            case 'payment_source_has_pending_charge':
                return 'Unable to unlink this payment source due to a recent change on an associated charge.';
            default:
                return 'An error has occured during the action.';
        }
    };

    render() {
        const { showModal, type, isLoading, error } = this.props;
        const spinner = <Spinner type="grow" color="black" size="sm" className="mr-1" />;
        const title = 'Payment Source';

        const message = (
            <span>
                {' '}
                Are you sure you want to <strong>{type}</strong> the {title}?
            </span>
        );

        return (
            <Modal isOpen={showModal} toggle={this.props.toggle}>
                <ModalHeader className="text-capitalize" toggle={this.props.toggle}>{`${type} ${title}`}</ModalHeader>
                <ModalBody>
                    <div className="my-3">{message}</div>
                    {error && (
                        <Alert color="danger">{error.code ? this.formatErrorMessage(error.code) : error.message}</Alert>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" disabled={isLoading} onClick={this.props.onSubmit}>
                        {isLoading ? spinner : null}
                        <span>Confirm</span>
                    </Button>
                    <Button color="secondary" disabled={isLoading} onClick={this.props.toggle}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}
