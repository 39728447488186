import { request, segmentListLimit } from './utils';
import { ListSegment } from './response';
import { stringify } from 'querystring';

export enum CHARGE_STATUS {
    CREATED = 'created',
    AUTHORIZED = 'authorized',
    CAPTURED = 'captured',
    CANCELED = 'canceled',
    ATTEMPTING_COLLECTION = 'attempting_collection',
    CAPTURE_FAILED = 'capture_failed',
    DISPUTED = 'disputed',
    REFUND_PENDING = 'refund_pending',
    REFUNDED = 'refunded',
    STOPPED_COLLECTION = 'stopped_collection',
}

export interface ChargeModel {
    id: string;
    type: 'bank_debit' | 'bank_debit_cnp';
    status: CHARGE_STATUS;
    merchant_convenience_fee: number;
    fee: number;
    amount: number;
    currency: string;
    customer_id: string;
    payment_source_id: string;
    external_id: string | null;
    created_at: string;
    authorized_at: string | null;
    captured_at: string | null;
    convenience_fee: number | null;
    payment_processing_fee: number | null;
}

export async function listCharges(
    options: {
        skip?: number;
        limit?: number;
        customerId?: string;
        status?: string[];
        from?: string;
        to?: string;
        search?: string;
    } = {},
) {
    const query = stringify({
        limit: segmentListLimit,
        ...options,
    });

    const res = await request<ListSegment<ChargeModel>>({
        method: 'GET',
        url: `/api/customers/charges?${query}`,
    });
    return res.result;
}
