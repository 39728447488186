import 'react';
import * as React from 'react';
import { connect, MapStateToPropsParam } from 'react-redux';
import { ApplicationState } from '../store';
import Layout from '../components/Layout';
import { bindActionCreators, Dispatch } from 'redux';
import Card from 'reactstrap/lib/Card';
import CardBody from 'reactstrap/lib/CardBody';
import MESSAGE from '../message';
import { ResponseErrorCode } from '../apis';
import Button from 'reactstrap/lib/Button';
import { faRedo } from '@fortawesome/free-solid-svg-icons/faRedo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface StateProps {
    show: boolean;
    errorCode: ResponseErrorCode | null;
}

interface OwnProps {
    children: JSX.Element | JSX.Element[];
}

type Props = StateProps & OwnProps;

class ErrorHandler extends React.Component<Props> {
    reloadPage = () => {
        window.location.reload();
    };

    render() {
        const { errorCode, show, children } = this.props;

        if (!show) {
            return children;
        }

        let title = <>{MESSAGE.ERROR_COMMON_TITLE}</>;
        let subTitle = null;
        let message = <>{MESSAGE.ERROR_COMMON}</>;

        if (errorCode === ResponseErrorCode.PayLinkCanceled) {
            title = <>{MESSAGE.CANCELED_LINK_TITLE}</>;
            message = <>{MESSAGE.CANCELED_LINK}</>;
        } else if (errorCode === ResponseErrorCode.PayLinkExpired) {
            title = <>{MESSAGE.EXPIRED_LINK_TITLE}</>;
            subTitle = <>{MESSAGE.EXPIRED_LINK_SUB_TITLE}</>;
            message = <>{MESSAGE.EXPIRED_LINK}</>;
        } else if (
            errorCode === ResponseErrorCode.InvalidChargeStatus ||
            errorCode === ResponseErrorCode.PayLinkMissingOrder ||
            errorCode === ResponseErrorCode.PayLinkMissingCharge
        ) {
            title = <>{MESSAGE.INVALID_CHARGE_STATUS_TITLE}</>;
            message = <>{MESSAGE.INVALID_CHARGE_STATUS}</>;
        } else if (errorCode === ResponseErrorCode.NotFound) {
            title = <>{MESSAGE.ORDER_NOT_FOUND_TITLE}</>;
            message = <>{MESSAGE.ORDER_NOT_FOUND}</>;
        } else if (errorCode === ResponseErrorCode.PaylinkChargeAmountModified) {
            title = <>{MESSAGE.ORDER_AMOUNT_MODIFIED_TITLE}</>;
            message = <>{MESSAGE.ORDER_AMOUNT_MODIFIED}</>;
        } else if (errorCode === ResponseErrorCode.MerchantSoftwareError) {
            title = <>{MESSAGE.ERROR_MERCHANT_SOFTWARE_TITLE}</>;
            message = <>{MESSAGE.ERROR_MERCHANT_SOFTWARE}</>;
        } else if (errorCode === ResponseErrorCode.InvalidApiKey) {
            title = <>{MESSAGE.ERROR_INVALID_CONFIGURATION_TITLE}</>;
            message = <>{MESSAGE.ERROR_INVALID_CONFIGURATION}</>;
        } else if (errorCode === ResponseErrorCode.LiveNotApproved) {
            title = <>{MESSAGE.ERROR_LIVE_NOT_APPROVED_TITLE}</>;
            message = <>{MESSAGE.ERROR_LIVE_NOT_APPROVED}</>;
        }

        return (
            <Layout navbar={false} centered>
                <Card className="shadow-lg text-center" style={{ maxWidth: 500 }}>
                    <CardBody className="p-md-5">
                        <div className="h4 mb-4">{title}</div>
                        {subTitle && <div className="lead mb-2">{subTitle}</div>}
                        <div className="lead mb-4">{message}</div>
                        {errorCode === ResponseErrorCode.PaylinkChargeAmountModified ? (
                            <Button className="mb-2" color="primary" onClick={this.reloadPage}>
                                Reload Page <FontAwesomeIcon icon={faRedo} fixedWidth />
                            </Button>
                        ) : (
                            <div className="text-center">
                                <a
                                    href="https://stronghold.co/happiness-support-contact"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Support
                                </a>
                            </div>
                        )}
                    </CardBody>
                </Card>
            </Layout>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, OwnProps, ApplicationState> = (state) => ({
    errorCode: state.global.error.errorCode,
    show: state.global.error.show,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ErrorHandler);
