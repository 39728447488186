export type TipType = 'percentage' | 'fixed';

export enum CustomizationType {
    SuccessScreen = 'portal::checkout:success_text/v1',
}

export interface TipCustomizationModel {
    type: TipType;
    value1: number;
    value2: number;
    value3: number;
}

export interface Customization {
    data: string;
    type: CustomizationType;
}

export interface CustomizationModel {
    tip: TipCustomizationModel;
    items: Customization[];
}
