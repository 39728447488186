import { PayLinkCallback } from '../types';
import querystring from 'querystring';
import url from 'url';
import { mapKeys } from 'lodash';
import { ApplicationState } from '../index';

export function getExitUrlCallback(state: ApplicationState) {
    if (!state.payLink.data) {
        return null;
    }
    const payLink = state.payLink.data;
    if (!payLink.callbacks || !payLink.callbacks.exit_url) {
        return null;
    }

    return getCallbackQuery(state, { callback: 'exit' });
}

export function getSuccessUrlCallback(state: ApplicationState) {
    if (!state.payLink.data) {
        return null;
    }
    const payLink = state.payLink.data;
    if (!payLink.callbacks || !payLink.callbacks.exit_url || payLink.status === 'created') {
        return null;
    }

    return getCallbackQuery(state, { callback: 'success' });
}

export function getCallbackQuery(state: ApplicationState, options: { callback: PayLinkCallback }) {
    if (!state.payLink.data) {
        throw new Error('No PayLink to get the callback query from.');
    }
    const payLink = state.payLink.data;

    if (!payLink.callbacks) {
        throw new Error('PayLink is not compatible with the callbacks feature.');
    }
    const callbacks = payLink.callbacks;

    const { callback } = options;
    let callbackUrl: string;

    let paymentSourceId: string | undefined;
    let chargeId: string | undefined;
    let tipId: string | undefined;
    if (callback === 'success') {
        callbackUrl = callbacks.success_url;
        paymentSourceId = state.paymentSources.paymentSourceSelected || undefined;
        chargeId = payLink.charge?.id;
        tipId = payLink.tip?.id;
    } else {
        callbackUrl = callbacks.exit_url;
    }

    const parsed = url.parse(callbackUrl);
    let result = `${parsed.protocol}//${parsed.host}${parsed.pathname}`;
    let merchantQuery = parsed.search ? querystring.parse(parsed.search.replace(/^(\?)/i, '')) : {};
    merchantQuery = mapKeys(merchantQuery, (value, key) => `custom_${key}`);
    const obj = {
        ...merchantQuery,
        pay_link_id: payLink.id,
        pay_link_type: payLink.type,
        pay_link_callback: callback,
        payment_source_id: paymentSourceId,
        charge_id: chargeId,
        tip_id: tipId,
    };

    const query = querystring.stringify(obj);
    result += `?${query}`;
    return result;
}
