import { AuthenticationState } from '../types';
import { DeepPartial, Reducer } from 'redux';
import { IS_AUTHENTICATED, SET_CUSTOMER_TOKEN, SET_TOKEN, UPDATE_CUSTOMER } from '../constants';
import { ApplicationAction } from '../actions';
import { merge } from 'lodash';
import { CustomerModel } from '../../apis';
import segment from '../../segment';

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const unloadedState: AuthenticationState = {
    isAuthenticated: false,
    token: null,
    customer: {
        country: null,
        id: null,
        state: null,
        individual: {
            email: null,
            mobile: null,
            date_of_birth: null,
            first_name: null,
            last_name: null,
        },
        payment_sources: [],
    },
    customerToken: null,
};

export const authenticationReducer: Reducer<AuthenticationState, ApplicationAction> = (
    state = unloadedState,
    action,
): AuthenticationState => {
    switch (action.type) {
        case IS_AUTHENTICATED:
            if (!action.payload.isAuthenticated) {
                return unloadedState;
            }
            return {
                ...state,
                isAuthenticated: action.payload.isAuthenticated,
            };
        case SET_TOKEN:
            return {
                ...state,
                token: action.payload.token,
            };
        case UPDATE_CUSTOMER:
            const updated = merge<DeepPartial<CustomerModel>, CustomerModel, DeepPartial<CustomerModel>>(
                {},
                state.customer,
                action.payload.customer,
            );
            segment.identify(updated.id as string);
            return {
                ...state,
                customer: updated,
            };
        case SET_CUSTOMER_TOKEN:
            return {
                ...state,
                customerToken: action.payload.token,
            };
    }

    return state;
};
