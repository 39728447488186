import axios from 'axios';
import { PortalResponse } from './response';
import { ApiKeyModel, Environment, MerchantModel } from './types';

export interface ConfigurationModel {
    api_environment: 'live' | 'sandbox';
    environment: Environment;
    merchant?: MerchantModel;
    publishable_api_key?: ApiKeyModel;
    stronghold_pay_host: string;
    sentry_dsn: string;
    analytics: {
        write_key?: string;
        environment: 'Development' | 'Sandbox' | 'Live';
    };
    split: {
        api_key: string;
    };
    integration_id: string;
    api_version: string;
}

export async function getConfiguration() {
    const res = await axios.get<PortalResponse<ConfigurationModel>>('/api/configuration');
    return res.data.result;
}
