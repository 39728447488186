// -----------------
// STATE - This defines the type of data maintained in the Redux store.

import { ResponseError, ResponseErrorCode } from '../../apis';
import { ApiKeyModel, ConfigurationModel, MerchantModel } from '../../apis';

export type ActionName =
    | 'login'
    | 'signup'
    | 'verify'
    | 'error'
    | 'set_error_message'
    | 'authenticate'
    | 'fetch_api_keys'
    | 'fetch_charges'
    | 'fetch_configuration'
    | 'fetch_payouts'
    | 'fetch_transactions'
    | 'fetch_customer_token'
    | 'fetch_customer_sources'
    | 'authorize_payment'
    | 'fetch_pay_link'
    | 'update_payment_source'
    | 'fetch_payment_sources'
    | 'set_pay_link_customer';

export interface ActionStatus {
    name: ActionName;
    isRequesting: boolean;
    responseError?: ResponseError;
}

export interface HeaderTitle {
    separator: string;
    values: string[];
}

// Split
export enum SPLIT_FLAG {
    SIMPLE_CHECKOUT = 'Simple_Checkout',
}

export type ConfigurationStatus = 'unloaded' | 'loaded' | 'error';

export interface GlobalState {
    actions: ActionStatus[];
    configuration: ConfigurationModel;
    configurationStatus: ConfigurationStatus;
    error: {
        errorCode: ResponseErrorCode | null;
        show: boolean;
    };
    headerTitle: HeaderTitle;
    publishableApiKey: ApiKeyModel;
    merchant: MerchantModel;
}
