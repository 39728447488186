import * as React from 'react';
import { connect, MapStateToPropsParam } from 'react-redux';
import { ApplicationState } from '../../store';
import { bindActionCreators, Dispatch } from 'redux';
import Card from 'reactstrap/lib/Card';
import CardBody from 'reactstrap/lib/CardBody';
import {
    selectPaymentSourceAction,
    setErrorMessageAction,
    updateHeaderTitleAction,
    updatePayLinkAction,
} from '../../store/actions';
import CardHeader from 'reactstrap/lib/CardHeader';
import selectors from '../../store/selectors';
import StrongholdPay from '../../components/StrongholdPay';
import { Charge, PaymentSource, TipOnSuccess } from '@stronghold/pay-dropin';
import { PayLinkModel, PayLinkTipModel } from '../../apis';
import PaymentSourceList from '../../components/PaymentSourceList';
import MESSAGE from '../../message';
import TipSelection from '../../components/TipSelection';
import TipMessage from '../../components/TipMessage';
import PaymentAuthorizationAggreement from '../../components/dropin/PaymentAuthorizationAggreement';
import AddTipDropinButton from '../../components/dropin/AddTipDropinButton';
import { SPLIT_FLAG, TipCustomizationModel } from '../../store/types';
import PayLinkSuccessCard from '../../components/PayLinkSuccessCard';
import PaymentSourceRequiredWarning from '../../components/PaymentSourceRequiredWarning';
import ToggleSplit from '../../components/split/ToggleSplit';
import Merchant from '../../components/Merchant';

interface StateProps {
    paymentSources: PaymentSource[];
    paymentSourceSelected: PaymentSource | null;
    isAuthorizingPayment: boolean;
    tipCustomization: TipCustomizationModel;
    tipData: PayLinkTipModel;
    charge: Charge;
}

interface DispatchProps {
    setErrorMessageAction: typeof setErrorMessageAction;
    updateHeaderTitleAction: typeof updateHeaderTitleAction;
    updatePayLinkAction: typeof updatePayLinkAction;
    selectPaymentSourceAction: typeof selectPaymentSourceAction;
}

interface OwnProps {
    link: PayLinkModel;
}

type Props = StateProps & OwnProps & DispatchProps;

interface State {
    tipValue: number;
    hasTipError: boolean;
}

class TipPage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            tipValue: selectors.number.getTipAmount(
                this.props.tipCustomization.type,
                this.props.tipCustomization.value2,
                this.props.charge.amount,
            ),
            hasTipError: false,
        };
    }

    async componentDidMount() {
        let title = 'Tip';
        if (this.props.tipData.beneficiary_name) {
            title = `${this.props.tipData.beneficiary_name} ${title}`;
        }
        this.props.updateHeaderTitleAction({
            values: [title],
        });
    }

    onSuccess: TipOnSuccess = (tip) => {
        this.props.updatePayLinkAction({
            status: 'used',
            tip,
        });
    };

    getAmount = () => this.state.tipValue;
    getAmountStr = () => selectors.number.amountToString(this.getAmount());

    renderOrderDetails = () => {
        const { charge, link, tipData } = this.props;

        return (
            <CardBody className="p-md-5 text-center border-top">
                <TipMessage
                    tipData={tipData}
                    merchant={link.merchant}
                    orderAmount={charge.amount}
                    orderCreatedAt={charge.created_at}
                />
            </CardBody>
        );
    };

    renderTip = () => {
        const { charge, tipCustomization } = this.props;
        return (
            <React.Fragment>
                <CardHeader>Add a tip</CardHeader>
                <CardBody className="p-md-5">
                    <TipSelection
                        originalAmount={charge.amount}
                        tipModel={tipCustomization}
                        amount={this.state.tipValue}
                        setDefaultValueOnChange
                        onSetAmount={(tipValue, hasTipError) => this.setState({ tipValue, hasTipError })}
                    />
                </CardBody>
            </React.Fragment>
        );
    };

    renderPaymentMethod = () => {
        const header = MESSAGE.PAYMENT_METHOD_SELECT_TITLE;

        return (
            <>
                <CardHeader>{header}</CardHeader>
                <CardBody className="p-md-5">
                    <PaymentSourceList
                        paymentSourceId={this.props.paymentSourceSelected?.id}
                        onSelect={(paymentSource) => this.props.selectPaymentSourceAction(paymentSource.id)}
                        disabled={this.props.isAuthorizingPayment}
                    />
                </CardBody>
            </>
        );
    };

    renderTotal = () => {
        const { isAuthorizingPayment, link, charge, tipData, paymentSourceSelected } = this.props;
        const { hasTipError } = this.state;

        return (
            <CardBody className="p-md-5 border-top">
                <div className="text-muted text-center mb-2">
                    Your order was {selectors.number.amountToString(charge.amount)}
                </div>
                <AddTipDropinButton
                    payLinkCode={link.id}
                    tip={{
                        chargeId: charge.id,
                        paymentSourceId: paymentSourceSelected?.id || '',
                        beneficiaryName: tipData.beneficiary_name,
                        amount: this.getAmount(),
                        details: tipData.details
                            ? {
                                  displayMessage: tipData.details.display_message,
                                  drawerId: tipData.details.drawer_id,
                                  terminalId: tipData.details.terminal_id,
                              }
                            : undefined,
                    }}
                    disabled={!paymentSourceSelected?.id || isAuthorizingPayment || hasTipError}
                    onSuccess={this.onSuccess}
                    size="lg"
                    className="mb-1"
                    block
                >
                    Tip {this.getAmountStr()}
                </AddTipDropinButton>
                {paymentSourceSelected ? (
                    <PaymentAuthorizationAggreement
                        merchantDisplayName={link.merchant.display_name}
                        paymentSourceLabel={paymentSourceSelected.label}
                        amount={this.getAmount()}
                    />
                ) : (
                    <PaymentSourceRequiredWarning />
                )}
            </CardBody>
        );
    };

    render() {
        const { link, tipData } = this.props;
        const { status } = link;

        if (status === 'used') {
            return (
                <PayLinkSuccessCard title="Success">
                    <p>
                        Your tip{' '}
                        <span>
                            to <span className="text-capitalize">{tipData.beneficiary_name}</span>
                        </span>{' '}
                        was received.
                    </p>
                    <div>{"Thanks for tipping! — We've updated your order receipt."}</div>
                </PayLinkSuccessCard>
            );
        }

        return (
            <Card className="mt-3">
                <CardBody className="p-md-5 text-center">
                    <ToggleSplit flag={SPLIT_FLAG.SIMPLE_CHECKOUT} unactiveComponent={<Merchant />}>
                        <StrongholdPay />
                    </ToggleSplit>
                </CardBody>
                {this.renderOrderDetails()}
                {this.renderTip()}
                {this.renderPaymentMethod()}
                {this.renderTotal()}
            </Card>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, OwnProps, ApplicationState> = (state, ownProps) => {
    return {
        paymentSources: state.paymentSources.arr,
        paymentSourceSelected: selectors.paymentSources.getPaymentSourceSelected(state),
        tipCustomization: state.global.merchant.customization.tip,
        isAuthorizingPayment: selectors.global.isActionRequesting(state.global.actions, 'authorize_payment'),
        tipData: ownProps.link.tip as PayLinkTipModel,
        charge: ownProps.link.charge as Charge,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            setErrorMessageAction,
            updateHeaderTitleAction,
            updatePayLinkAction,
            selectPaymentSourceAction,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(TipPage);
