let environment: string | null;

export default {
    set environment(value: string | null) {
        environment = value;
    },

    identify(userid: string) {
        if (!environment) return;
        analytics.identify(userid);
    },
};
