import * as React from 'react';
import { FormEvent } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Spinner from 'reactstrap/lib/Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons/faLongArrowAltRight';
import { connect, MapStateToPropsParam } from 'react-redux';
import { ApplicationState } from '../store';
import selectors from '../store/selectors';
import { AuthenticationMethod, ResponseError, ResponseErrorCode } from '../apis';
import { verifyAction } from '../store/actions';
import { bindActionCreators, Dispatch } from 'redux';
import MaskedInput from 'react-text-mask';
import FormFeedback from './FormFeedback';
import FormGroup from 'reactstrap/lib/FormGroup';
import Form from 'reactstrap/lib/Form';
import MESSAGE from '../message';

interface StateProps {
    isRequesting: boolean;
    error: ResponseError | null;
}

type OwnProps = {
    show: boolean;
    authenticationMethod: AuthenticationMethod;
    value: string;
    isResending: boolean;
    onClose: () => void;
    onResend: () => void;
    onSuccess?: () => void;
};

interface DispatchProps {
    verify: typeof verifyAction;
}

type Props = OwnProps & StateProps & DispatchProps;

type State = {
    code: string;
};

class VerificationCodeModal extends React.Component<Props> {
    public state: State = {
        code: '',
    };

    onToggle = () => {
        this.props.onClose();
    };

    onSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        try {
            await this.props.verify(this.state.code);
            if (this.props.onSuccess) {
                this.props.onSuccess();
            }
        } catch {
            // Error
        }
    };

    render() {
        const { show, isRequesting, error, isResending } = this.props;

        return (
            <Modal isOpen={show} toggle={this.onToggle} centered>
                <Form onSubmit={this.onSubmit}>
                    <ModalHeader toggle={this.onToggle}>Verification</ModalHeader>
                    <ModalBody>
                        <p>
                            Enter the verification code sent to <strong>{this.props.value}</strong>.
                        </p>
                        <FormGroup className="mb-0">
                            {this.props.show ? (
                                <MaskedInput
                                    mask={[/[0-9]/, ' ', /[0-9]/, ' ', /[0-9]/, ' ', /[0-9]/]}
                                    type="text"
                                    placeholder="X X X X"
                                    className={[
                                        'form-control form-control-lg text-center',
                                        error ? 'is-invalid' : '',
                                    ].join(' ')}
                                    onChange={(e) => this.setState({ code: e.currentTarget.value })}
                                    ref={(input) => {
                                        setTimeout(() => {
                                            if (input && input.inputElement) {
                                                input.inputElement.focus();
                                            }
                                        }, 500);
                                    }}
                                />
                            ) : null}
                            <FormFeedback
                                error={error}
                                options={{
                                    transform: (responseErrorCode: ResponseErrorCode) => {
                                        if (responseErrorCode === ResponseErrorCode.InvalidField) {
                                            return `${MESSAGE.ERROR_INCORRECT_CODE} ${MESSAGE.CHECK_AND_TRY_AGAIN}`;
                                        }
                                    },
                                }}
                            />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter className="d-flex justify-content-between">
                        <Button color="light" onClick={this.props.onResend} disabled={isResending}>
                            Resend
                        </Button>
                        <Button type={'submit'} disabled={isResending || this.props.isRequesting} color="primary">
                            Verify{' '}
                            {isRequesting || isResending ? (
                                <Spinner type="grow" color="white" size={'sm'} />
                            ) : (
                                <FontAwesomeIcon icon={faLongArrowAltRight} fixedWidth />
                            )}
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, OwnProps, ApplicationState> = (state) => ({
    error: selectors.global.getResponseError(state.global.actions, 'verify'),
    isRequesting: selectors.global.isActionRequesting(state.global.actions, 'verify'),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            verify: verifyAction,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(VerificationCodeModal);
